(function (){
  angular.module('kmi.lms.user.common')
    .component('userCityInput', {
      template: require('ajs/modules/user/components/user-city-input.html').default,
      controller: UserCityInputController,
      controllerAs: 'vm',
      bindings: {
        user: '=',
        addressPattern: '<?',
        formSubmitted: '=',
      }
    });

  function UserCityInputController(){
    const vm = this;

    vm.$onInit = onInit;
    function onInit(){}
  }
})();
