(function () {

  angular.module('kmi.lms.user.notifications')
    .directive('userNotificationsHome', userNotificationsHome);

  function userNotificationsHome() {
    return {
      restrict: 'AE',
      replace: true,
      template: require('ajs/modules/user/notifications/tile/notifications.html').default,
      scope: {
        notificationsCount: '='
      },
      controller: UserNotificationsHomeController,
      controllerAs: 'vm',
      bindToController: true
    };
  }

  /* @ngInject*/
  function UserNotificationsHomeController(userNotificationsService, courseQuizTypes, $scope, siteSettingService, _) {
    var vm = this;

    vm.courseQuizTypes = courseQuizTypes;
    vm.unpinnedNotifications = [];
    vm.profileIncomplete = null;
    vm.siteSettings = siteSettingService.getSiteSetting();

    vm.show = userNotificationsService.showNotifications;
    vm.markAsViewed = markAsViewed;
    vm.$onInit = onInit;

    function onInit() {
      vm.notificationsCount = 0;
      $scope.$on('event:notifications:updated', updateNotifications);

      userNotificationsService.check();
    }

    function updateNotifications(event, data) {
      vm.notificationsCount = data.notifications.length;

      userNotificationsService.assignNotificationActions(data.notifications);

      vm.profileIncomplete = _.find(data.notifications, ['type', 'profileIncomplete']) || null;
      vm.upcomingEvents = _.filter(data.notifications, {type: 'upcomingEvents'});
      vm.unpinnedNotifications = _.filter(data.notifications, function (item) {
        return item.type !== 'profileIncomplete' && item.type !== 'upcomingEvents';
      });
    }

    function markAsViewed(notification) {
      userNotificationsService.excludeNotification(notification);
    }
  }
})();
