(function(){

  /**
   * @ngdoc service
   * @name kmi.lms.quiz.service:QuizResult
   * @description
   * Represents QuizResult data model.
   * Implements all server's CRUD methods for quiz result.
   */

  angular.module('kmi.lms.quiz')
    .factory('QuizResult', QuizResultService);

  /* @ngInject */
  function QuizResultService($resource, $http, quizEnums, quizQuestionEnums, _){
    var QuizResult = $resource('/a/quizzes/results/:resultId/', {resultId: '@id'}, {},
      {
        stripTrailingSlashes: false
      });

    /**
     * @description
     * Posts provided question answer to the server.
     * @param question
     * @returns {*|HttpPromise|IHttpPromise<T>|void}
     */
    QuizResult.prototype.$saveQuestionAnswer = function(question){
      return $http.put(['/a/quizzes/results/',this.id,'/question_answer/'].join(''), question);
    };

    /**
     * @description
     * Update start date for quiz and receive available time if it exists.
     * @returns {*|HttpPromise|IHttpPromise<T>|void}
     */
    QuizResult.prototype.$start = function(){
      return $http.put(['/a/quizzes/results/',this.id,'/start/'].join(''));
    };

    QuizResult.prototype.$finish = function(handleQuizResult){
      var parameters = {};
      if (handleQuizResult){
        parameters.handleQuizResult = true;
      }

      return $http.put(['/a/quizzes/results/',this.id,'/'].join(''), parameters)
        .then(function (response){
          angular.extend(this, response.data);
        }.bind(this));
    };

    /**
     * @description
     * Track quiz activity.
     * @returns {*|HttpPromise|IHttpPromise<T>|void}
     */
    QuizResult.prototype.$timeTracking = function(){
      return $http.put(['/a/quizzes/results/',this.id,'/time_tracking/'].join(''));
    };

    /**
     * @description
     * Check whether the quiz is not in progress state.
     * @returns {boolean}
     */
    QuizResult.prototype.isCompleted = function(){
      return this.statusId === quizEnums.resultStatus.passed ||
        this.statusId === quizEnums.resultStatus.failed ||
        this.statusId === quizEnums.resultStatus.completed ||
        this.statusId === quizEnums.resultStatus.pending;
    };

    /**
     * @description
     * Check whether the quiz is not in progress state.
     * @returns {boolean}
     */
    QuizResult.prototype.pending = function(){
      return this.statusId === quizEnums.resultStatus.pending;
    };

    /**
     * @description
     * Check whether answer is provided for the question
     * @param question
     * @returns {*}
     */
    QuizResult.prototype.questionHasAnswer = function(question){
      if (question.optional) {
        return true;
      }

      //For questions with the predefined choice at least one choice should be selected.
      if(_.includes([quizQuestionEnums.questionType.checkAllThatApply, quizQuestionEnums.questionType.multiChoice], question.typeId)){
        return _.find(question.answerVariants, function (variant) {
          return variant.selected && (!variant.otherRequired || variant.otherRequired && variant.other);
        });
      }

      //For text questions this text should be filled in
      if(question.typeId === quizQuestionEnums.questionType.shortAnswer){
        return !!question.answer;
      }

      //For matching questions this text should be filled in
      if(question.typeId === quizQuestionEnums.questionType.matching){
        return !_.find(question.answerVariants, function(o) { return !o.answerText; });
      }

      //For fillin questions a text should be filled in
      if(question.typeId === quizQuestionEnums.questionType.fillin){
        return !_.find(question.answerVariants, function(o) { return !o.answerText; });
      }

      //For unhandled question type return true.
      return true;
    };

    return QuizResult;
  }

})();
