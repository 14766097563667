(function () {

  angular
    .module('kmi.lms.user.common')
    .factory('userRolesService', userRolesService);

  /* @ngInject */
  function userRolesService($http) {
    return {
      getRoles: getRoles
    };

    function getRoles(params) {
      return $http.get('/a/user/roles/', {params: params})
        .then(function (response) {
          return response.data;
        });
    }
  }
})();
