(function () {

  angular.module('kmi.lms.user.mail')
    .component('unsubscribeComponent', {
      template: require('ajs/modules/user/mail/opt_in/unsubscribe.html').default,
      controller: UnsubscribeController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function UnsubscribeController($uiRouterGlobals, userEmailOptInUnsubscribeService, siteSettingService, currentUser,
    navigationService, globalConfig, $state) {
    var vm = this;
    vm.$onInit = onInit;

    vm.view = '';
    vm.processing = false;
    vm.siteSettings = {};
    vm.userSettings = {};
    vm.unsubscribeEmail = '';

    vm.state = $state;
    vm.goHome = navigationService.goHome;
    vm.emailOptIn = globalConfig.settings.emailOptIn;
    vm.signInWording = globalConfig.signInWording;

    vm.unsubscribe = unsubscribe;

    function onInit() {
      vm.siteSettings = siteSettingService.getSiteSetting();
      vm.userSettings = currentUser.get();

      userEmailOptInUnsubscribeService.get($uiRouterGlobals.params.unsubscribeCode).then(function (email) {
        vm.unsubscribeEmail = email;
        vm.view = 'initial';
      }, function () {
        vm.view = 'error';
      });
    }

    function unsubscribe() {
      vm.processing = true;

      userEmailOptInUnsubscribeService.unsubscribe($uiRouterGlobals.params.unsubscribeCode).then(function () {
        vm.view = 'unsubscribed';
        vm.processing = false;
      }, function () {
        vm.view = 'error';
        vm.processing = false;
      });
    }
  }
})();
