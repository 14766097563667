(function () {

  angular
    .module('kmi.lms.user.learningSeries')
    .component('userLearningSeriesStructure', {
      template: require('ajs/modules/user/learning-series/components/user-learning-series-structure.html').default,
      controller: UserLearningSeriesStructureController,
      controllerAs: 'vm',
      bindings: {
        learningSeriesUser: '=',
        lsStat: '=',
        adminView: '<?',
        legacyView: '<?' // TODO Remove
      }
    });

  /* @ngInject */
  function UserLearningSeriesStructureController($scope, learningSeriesService, currentUser,
    userLearningSeriesService, globalConfig,  _) {
    var vm = this;

    vm.courseRequirementTypes = learningSeriesService.courseRequirementTypes;
    vm.isAnonymous = currentUser.get().anonymous;
    vm.globalCourseRegistrationSettings = globalConfig.courseRegistration || {};

    vm.isNumber = angular.isNumber;
    vm.$onInit = onInit;

    function onInit() {
      loadStructure();

      $scope.$on('event:course.registration.updated', function (event, registration) {
        let course = null;
        vm.lsStructure.every(function (section) {
          if (course){
            return false;
          }
          course = _.find(section.items, lsCourse => lsCourse.courseId === registration.courseId);
          return true;
        });

        if (course) {
          if (course.statusId !== registration.statusId){
            loadStructure();
          }
        }
      });
    }

    function loadStructure() {
      if (!vm.lsStructure) {
        vm.loading = true;
      }

      userLearningSeriesService.getLearningSeriesStructure(vm.learningSeriesUser.userId, vm.learningSeriesUser.learningSeriesId)
        .then(function (data) {
          vm.lsStructure = data.learningSeries.items;
          vm.lsStat = data.stats;
        })
        .finally(function () {
          vm.loading = null;
        });
    }
  }

})();
