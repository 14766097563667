(function () {

  angular
    .module('kmi.lms.search.common', ['ui.select', 'kmi.lms.learningSeries', 'kmi.lms.course.common',
      'kmi.lms.competency', 'kmi.lms.core', 'kmi.lms.course.registrations',
      'kmi.lms.courseProviders', 'kmi.lms.user.common'])
    .constant('searchTerms', ['advanced', 'audience_id', 'author_id', 'competency_id', 'competency_node_id',
      'course_certificate_available', 'course_format_type_id', 'cp_organization_id', 'created_date', 'created_by_user',
      'credit_type_id', 'device_compatibility', 'distance', 'format_id', 'group_id', 'label_id', 'language_id',
      'last_reviewed', 'ls_id', 'max_list_price', 'max_start_date', 'max_updated_date', 'min_start_date',
      'min_updated_date', 'perlc_course_attribute_id', 'provider_user_id', 'quality', 'query', 'rating',
      'filter', 'sort', 'subject', 'tag', 'type', 'national_cp_only',
      'approval_status_id', 'active', 'showInactive', 'expired', 'showExpired', 'showUnpublished', 'certificate',
      'required_courses_only', 'training_category', 'min_price', 'max_price', 'free', 'user_roles', 'include_subgroups',
      'page', 'my_organization']);
  //   .config(moduleConfig);
  //
  // function moduleConfig($stateProvider, searchTerms) {
  //   $stateProvider
  //     .state('edit.searchFilters', {
  //       'url': '^/search-filters?' + searchTerms.join('&') + '&customFilters&returnState&catalogType',
  //       'params': window._.reduce(searchTerms.concat(['customFilters', 'returnState', 'catalogType']), function (obj,
  //         key) {
  //         obj[key] = {
  //           'value': null,
  //           'dynamic': true
  //         };
  //         return obj;
  //       }, {}),
  //       component: 'searchFullScreenFilters',
  //       resolve: {
  //         filters: function (searchFilters, $transition$, $state, stateSearchFilters, _) {
  //           var filtersData = _.get(stateSearchFilters, $transition$.params().returnState);
  //           if (filtersData && $transition$.params().customFilters && $transition$.params().customFilters.length) {
  //             var availableFilters = _.intersection(filtersData.filters, $transition$.params().customFilters);
  //             var filtersAll = _.map(availableFilters, function (name) {
  //               var filter = new searchFilters[name]();
  //               filter.load();
  //               return filter;
  //             });
  //             return {
  //               ui: filtersAll,
  //               all: filtersAll
  //             };
  //           } else {
  //             return searchFilters.loadFilters();
  //           }
  //         },
  //         returnState: function ($transition$) {
  //           return $transition$.params().returnState;
  //         }
  //       },
  //       data: {
  //         label: 'Search Filters',
  //         availableFor: ['anonymous', 'regularUser', 'admin'],
  //       }
  //     });
  // }
})();
