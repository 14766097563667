(function () {

  angular.module('kmi.lms.user.certificates')
    .component('certificateReplacementDialog', {
      template: require('ajs/modules/user/certificates/modal/certificate-replacement-component.html').default,
      controller: CertificateReplacementDialogController,
      controllerAs: 'vm',
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  /* @ngInject */
  function CertificateReplacementDialogController() {
    var vm = this;

    vm.cancel = cancel;
    vm.ok = ok;

    function cancel() {
      vm.modalInstance.dismiss('cancel');
    }

    function ok() {
      vm.modalInstance.close();
    }
  }
})();
