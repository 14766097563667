(function(){

  angular.module('kmi.lms.user.components', [
    'ui.bootstrap',
    'ui.mask',
    'globalDependencies',
    'kmi.lms.core',
    'kmi.lms.components',
    'kmi.lms.user.common',
    'kmi.lms.customAttributes.common',
    'kmi.lms.customAttributes.user',
    'kmi.lms.user.mail',
  ])
    .value('avatarAllowedFileTypes', /(\.|\/)(gif|jpe?g|png|bmp)$/i)
    // Double backslash is needed because angular removes not escaped backslashes when insert pattern into nh-pattern
    .constant('regexPatterns', {
      password: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).+$',
      internationalZip: '^[A-Za-z0-9]{1,10}$',
      usZip: '^\\d{5}(-\\d{4})?$'
    })
    .run(moduleRun);

  function moduleRun($templateCache) {
    $templateCache.put(
      'modules/user/edit/reset-password/reset.html',
      require('ajs/modules/user/edit/reset-password/reset.html').default
    );

    $templateCache.put(
      'modules/user/edit/components/modal/change-password-modal.html',
      require('ajs/modules/user/edit/components/modal/change-password-modal.html').default
    );

    $templateCache.put(
      'modules/user/edit/reset-password/expired.html',
      require('ajs/modules/user/edit/reset-password/expired.html').default
    );
  }
})();
