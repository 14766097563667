(function () {

  angular.module('kmi.lms.core')
    .component('policiesSignOffComponent', {
      template: require('ajs/modules/user/edit/policies-sign-off/policies-sign-off.html').default,
      controller: PoliciesSignOffController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function PoliciesSignOffController(backUrlService, policiesService, User, serverErrorHandlerService) {
    var vm = this;
    vm.$onInit = onInit;

    vm.policiesSignOffSubmit = policiesSignOffSubmit;

    function onInit() {
      policiesService.getPolicies().then(bindPolicies);
    }

    function bindPolicies(policies) {
      if(!policies) {
        backUrlService.transitionToLastState();
      }

      vm.policies = policies;
      bindUser();
    }

    function bindUser() {
      User.get().then(function (user) {
        vm.user = user;
      });
    }

    /**
     * Save user policies
     */
    function policiesSignOffSubmit() {
      vm.loading = true;
      vm.submitted = true;

      if (vm.user.policiesSignOff) {
        vm.user.onlyPoliciesUpdate = true;

        vm.user.save().then(function () {
          backUrlService.transitionToLastState();
        }, function (reason) {
          return serverErrorHandlerService.handleForbiddenError(reason, function (data) {
            vm.error = data;
          });
        }).finally(function () {
          vm.loading = false;
        });

      } else {
        vm.loading = false;
      }
    }
  }
})();
