(function () {

  angular
    .module('kmi.lms.user.achievements', [
      'ngResource',
      'kmi.lms.core',
      'kmi.lms.user.common',
      'kmi.lms.achievements',
    ]);

})();
