(function () {
  angular.module('kmi.lms.user.common')
    .component('userEmailInput', {
      template: require('ajs/modules/user/components/user-email-input.html').default,
      controller: UsernEmailInputController,
      controllerAs: 'vm',
      bindings: {
        user: '=',
        formSubmitted: '=',
        useAsLogin: '<?'
      }
    });

  function UsernEmailInputController(globalConfig, _) {
    const vm = this;

    vm.$onInit = onInit;
    vm.emailChange = emailChange;

    function onInit() {
      vm.emailAsLogin = vm.useAsLogin || _.get(globalConfig, 'settings.user.emailAsLogin');
    }

    function emailChange() {
      if (vm.emailAsLogin) {
        vm.user.loginName = vm.user.email;
      }
    }
  }
})();
