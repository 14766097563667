(function () {

  /**
   * @ngdoc component
   * @name kmi.lms.core.component:ResetPasswordController
   * @description
   * Show reset password requirement step
   * @requires currentUser
   * @requires $scope
   * @requires $state
   * @requires serverErrorHandlerService
   *
   */
  angular.module('kmi.lms.core')
    .component('resetPasswordComponent', {
      template: require('ajs/modules/user/edit/reset-password/template.html').default,
      controller: ResetPasswordController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ResetPasswordController(backUrlService, currentUser, User, $state, serverErrorHandlerService,
    elmsAuthService, rootScopeService, globalConfig) {
    var vm = this;

    vm.signInWording = globalConfig.signInWording;
    vm.$onInit = onInit;
    vm.submit = submit;
    vm.cancel = cancel;
    vm.isExpiredPasswordPage = $state.current.name === 'prompt.expiredPassword';

    function onInit() {
      User.get().then(function(user) {
        vm.user = user;
      });
    }

    /**
     * @description
     * Validate and save new password
     */
    function submit() {
      var data = {'password': vm.user.password, 'oldPassword': vm.user.oldPassword};

      vm.user.changePassword(data).then(function () {
        vm.successful = true;
        currentUser.loadData().then(function () {
          backUrlService.transitionToLastState();
        });
      },
      function (reason) {
        if (reason.status === 403 && reason.data && reason.data.code === 'PASSWORD_RULES_FAILED') {
          rootScopeService.broadcast('event:passwordRulesStatusUpdated', reason.data.context);
        }

        return serverErrorHandlerService.handleForbiddenError(reason, function (data) {
          vm.error = data;
        });
      }
      );
    }

    /**
     * @description
     * Logout user
     */
    function cancel() {
      elmsAuthService.logout()
        .then(() => currentUser.loadData())
        .then(function () {
          $state.go('prompt.login');
        });
    }
  }

})();
