(function () {

  angular.module('kmi.lms.search.common')
    .value('searchSortTerms', [
      {name: 'Relevance', value: 'relevance'},
      {name: 'Name: A-Z', value: 'asc'},
      {name: 'Name: Z-A', value: 'desc'},
      {name: 'Newest', value: 'newest'}
    ])
    // .value('adminSearchSortTerms', [
    //   {title: 'Sort by relevance', term: 'relevance'},
    //   {title: 'Sort A-Z', term: 'asc'},
    //   {title: 'Sort Z-A', term: 'desc'},
    //   {title: 'Sort by newest', term: 'newest'},
    //   {title: 'Sort by recently modified', term: 'last_modified'}
    // ])
    // .value('adminSearchShowResultsTerms', [
    //   {title: 'Show Inactive', term: 'showInactive'},
    //   {title: 'Show Expired', term: 'showExpired'}
    // ])
    .service('searchFilters', function (Class, $location, courseService, currentUser, $window, EventDispatcher,
      learningSeriesService, $injector, _, moment, searchFilterBase, searchSortTerms, globalConfig) {
      var self = this,
        filters = {
          ui: [],
          all: [],
          advanced: []
        };

      /**
     * @description
     * Default sort terms
     */
      this.sortTerms = searchSortTerms;
      this.adminSortTerms = _.get(globalConfig.settings, 'search.sortItems');
      this.adminShowResultsTerms = _.get(globalConfig.settings, 'search.terms');

      /**
     * @description
     * Abstract class which handles search options provided though the query parameters.
     */
      this.SearchFilterBase = Class.extend({
        appearance: 'panel',
        displayLimit: 10,
        /**
       * @description
       * Toggle provided search criteria. Adds it to the search query if it hasn't already exists or
       * removes in the another case.
       * If search filter allow multiple options they'll be separated by the comma
       * @param value
       */
        exec: function (value, term) {
          if (!term) {
            term = this.term;
          }
          var search = $location.search(),
            existingConditions = [];

          //Cast to string and trim
          value = (value + '').trim();

          if (search[term]) {

            existingConditions = search[term] instanceof Array ? search[term] : search[term].split(',');
          }

          if (!_.find(existingConditions, function (i) {
            return i.trim() === value;
          })) {
            if (this.multiChoice) {
              existingConditions.push(value);
            }
            else {
              existingConditions = [value];
            }
          }
          else {
            existingConditions = _.without(existingConditions, value);
          }

          existingConditions = _.uniq(existingConditions).join(',');
          if (existingConditions) {
            search[term] = existingConditions;
          }
          else {
          //remove empty condition
            delete search[term];
          }

          //update query string
          $location.search(search);
        },
        /**
       * @description
       * Clears selected option for the filter. Removes search criteria from the query string
       */
        clearSelection: function () {
          var search = $location.search();
          if (search[this.term]) {
            delete search[this.term];
          }

          $location.search(search);
        },
        _setVisibility: function () {
          if (this.dependency) {
            var filter, that = this;

            this.visible = _.every(_.isArray(this.dependency) ? this.dependency : [this.dependency], function (dependency) {
              switch (typeof dependency) {
                case 'boolean':
                  return dependency;

                case 'function':
                  return dependency.call(that);

                case 'object':
                  if (dependency.filter) {
                    if (typeof dependency.filter === 'function') {
                      filter = new dependency.filter(); // eslint-disable-line new-cap
                    } else {
                      filter = dependency.filter;
                    }
                    filter.extractConditions();

                    if (dependency.condition) {
                      return _.intersection(filter.selectedOptions, _.isArray(dependency.condition) ? dependency.condition : [dependency.condition]).length > 0;
                    }
                    else {
                      return !!(filter.selectedOptions && filter.selectedOptions.length);
                    }

                  }
                  return false;

                default:
                  return false;
              }
            });

            if (!this.visible) {
              this.clearSelection();
            }

            this.dispatchEvent('filtersChanged');
          }
          else {
            this.visible = true;
          }

          // set visible false if enabled filter from prohibition
          if (this.prohibition) {
            var prohibitFilter;
            if (typeof this.prohibition.filter === 'function') {
              prohibitFilter = new this.prohibition.filter(); // eslint-disable-line new-cap
            } else {
              prohibitFilter = this.prohibition.filter;
            }

            prohibitFilter.extractConditions();

            if (this.prohibition.condition) {
              this.visible = _.intersection(prohibitFilter.selectedOptions, _.isArray(this.prohibition.condition) ? this.prohibition.condition : [this.prohibition.condition]).length === 0;
            }
            else {
              this.visible = !(prohibitFilter.selectedOptions && prohibitFilter.selectedOptions.length);
            }
          }
        },
        /**
       * @description
       * Parse query string and find search options related to the filter condition.
       * If filter have dependant filters this method will be called for them too.
       */
        extractConditions: function () {
          this.getSelectedOptions();
          this._initSelectedItems();
        },
        /**
       * @description
       * Iterates through the filter options and mark option as "selected"
       * if corresponding option exists in the query string
       * @private
       */
        _initSelectedItems: function () {
          var that = this;
          this.items = _.map(this.items, function (item) {
            return angular.extend(item, {selected: _.includes(that.selectedOptions || [], item.value)});
          });

          this.selectedItems = _.filter(this.items, 'selected');

          // Fill selected items list with the generic names if there are no items which passed as search filter available
          if (this.items.length) {
            this._addGenericItem();
          }

          this._setVisibility();
        },
        getSelectedOptions: function () {
          var conditions = $location.search();
          this.selectedOptions = conditions[this.term] && (conditions[this.term] + '').split(',');
        },
        _addGenericItem: function () {
          if (this.selectedOptions && this.selectedOptions.length) {
            var selectedIds = _.keyBy(this.selectedItems, 'value');

            for (var i = this.selectedOptions.length - 1; i >= 0; i--) {
              if (!selectedIds[this.selectedOptions[i]]) {
                this.selectedItems.push({
                  text: this.label + ' ' + this.selectedOptions[i],
                  value: this.selectedOptions[i],
                  id: this.selectedOptions[i]
                });
              }
            }
          }

        },
        load: function () {
          return Promise.resolve();
        },
        init: function () {
          EventDispatcher.call(this);
        }
      });

      /**
     * @description
     * Course Format filter. Formats loads from WS.
     * This filter depends on the ObjectFilter and available only when "courses" selected
     * @type {*|any|void|Object}
     */
      this.CourseFormatFilter = this.SearchFilterBase.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            name: 'CourseFormatFilter',
            term: 'format_id',
            label: 'Format',
            mode: 'multiChoice',
            //For backward compatibility
            multiChoice: true,
            items: [],
            open: true,
            dependency: {
              filter: self.ObjectFilter,
              condition: 'course'
            }
          });
        },
        load: function () {
          return courseService.getCourseOptions().then(function (options) {
            this.items = _.map(options.formats, function (item) {
              return {
                id: item.id,
                text: item.name,
                value: item.id + ''//cast to string
              };
            });

            this._initSelectedItems();
          }.bind(this));
        }
      });

      this.CourseFormatTypeFilter = this.SearchFilterBase.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            name: 'CourseFormatTypeFilter',
            term: 'course_format_type_id',
            label: 'Format',
            mode: 'multiChoice',
            //For backward compatibility
            multiChoice: true,
            items: [],
            open: false,
            dependency: {
              filter: self.ObjectFilter,
              condition: 'course'
            }
          });
        },
        load: function () {
          return courseService.getCourseOptions().then(function (options) {
            this.items = _.map(options.formatTypes, function (item) {
              return {
                id: item.id,
                text: item.name,
                value: item.id + ''//cast to string
              };
            });

            this._initSelectedItems();
          }.bind(this));
        }
      });

      /**
     * @description
     * Course language filter. Languages loads from WS.
     * This filter depends on the ObjectFilter and available only when "courses" selected
     * @type {*|any|void|Object}
     */
      this.CourseLanguageFilter = this.SearchFilterBase.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            name: 'CourseLanguageFilter',
            term: 'language_id',
            label: 'Language',
            mode: 'multiChoice',
            //For backward compatibility
            multiChoice: true,
            items: [],
            dependency: {
              filter: self.ObjectFilter,
              condition: 'course'
            }
          });
        },
        load: function () {
          return courseService.getCourseOptions().then(function (options) {
            this.items = _.map(options.languages, function (item) {
              return {
                id: item.id,
                text: item.name,
                value: item.id + ''
              };
            });

            this._initSelectedItems();
          }.bind(this));
        }
      });

      //   /**
      //  * @description
      //  * Competency filter. Doesn't represents UI filter. Filter options loads from WS on demand when competency filter
      //  * exists in the query string.
      //  * @type {*|any|void|Object}
      //  * @deprecated was used only for ACC and no longer needed.
      //  */
      //   this.CompetencyFilter = this.SearchFilterBase.extend({
      //     init: function () {
      //       this._super();

      //       angular.extend(this, {
      //         name: 'CompetencyFilter',
      //         term: 'competency_id',
      //         label: 'Competency',
      //         mode: 'multiChoice',
      //         //For backward compatibility
      //         multiChoice: true,
      //         items: []
      //       });
      //     },
      //     _initSelectedItems: function () {
      //       var that = this;

      //       this._super();

      //       _.forEach(this.selectedOptions, function (competencyId) {
      //         var competency = $injector.get('Competency');

      //         if (competency) {
      //           competency.get({competencyId: competencyId}, function (competency) {
      //             that.selectedItems.push({
      //               value: competencyId,
      //               text: competency.name || 'Competency'
      //             });
      //           }, function () {
      //             that._addGenericItem();
      //           });
      //         }
      //       });
      //     }
      //   });

      /**
     * @description
     * Learning series filter.Doesn't represents UI filter. Filter options loads from WS on demand when ls filter
     * exists in the query string.
     * @type {*|any|void|Object}
     */
      this.LsFilter = this.SearchFilterBase.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            name: 'LsFilter',
            term: 'ls_id',
            label: 'Learning Series',
            mode: 'multiChoice',
            //For backward compatibility
            multiChoice: true,
            items: []
          });
        },
        _initSelectedItems: function () {
          var that = this;

          this._super();
          _.forEach(this.selectedOptions, function (lsId) {
            learningSeriesService.getLearningSeriesForUser(lsId).then(function (response) {
              that.selectedItems.push({
                value: lsId,
                text: _.get(response, 'data.name') || 'Learning Series'
              });
            }, function () {
              that._addGenericItem();
            });
          });
        }
      });

      /**
     * @description
     * Credit types filter.Doesn't represents UI filter. Filter options loads from WS on demand when credit type filter
     * exists in the query string.
     * @type {*|any|void|Object}
     */
      this.CreditTypeFilter = this.SearchFilterBase.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            name: 'CreditTypeFilter',
            term: 'credit_type_id',
            label: 'Credit Type',
            mode: 'multiChoice',
            //For backward compatibility
            multiChoice: true,
            items: [],
            dependency: {
              filter: self.ObjectFilter,
              condition: 'course'
            }
          });
        },
        load: function () {
          return courseService.getCourseOptions().then(function (options) {
            this.items = _.map(options.creditTypes, function (item) {
              return {
                id: item.id,
                text: item.name,
                value: item.id + ''
              };
            });

            this._initSelectedItems();
          }.bind(this));
        }
      });

      /**
     * @description
     * Target Audiences filter.Doesn't represents UI filter. Filter options loads from WS on demand when
     * target audience filter exists in the query string.
     * @type {*|any|void|Object}
     */
      this.TargetAudienceFilter = this.SearchFilterBase.extend({
        init: function (attrs) {
          this._super();

          angular.extend(this, {
            name: 'TargetAudienceFilter',
            term: 'audience_id',
            label: 'Audience',
            mode: 'multiChoice',
            //For backward compatibility
            multiChoice: true,
            items: [],
            dependency: {
              filter: self.ObjectFilter,
              condition: 'course'
            }
          });

          angular.extend(this, attrs);
        },
        load: function () {
          return courseService.getCourseOptions().then(function (options) {
            this.items = _.map(options.targetAudiences, function (item) {
              return {
                id: item.id,
                text: item.name,
                value: item.id + ''
              };
            });

            this._initSelectedItems();
          }.bind(this));
        }
      });

      /**
     * @description
     * Number filter.
     * @type {*|any|void|Object}
     */
      this.NumberFilter = this.SearchFilterBase.extend({
        _initSelectedItems: function () {
          var conditions = $location.search();
          this.value = parseInt(conditions[this.term]);
          this.selectedItems = this.value ? [{value: this.value}] : [];
          this.appliedDate = !!(conditions[this.term] || conditions[this.date_term]);

          this._setVisibility();
        },
        itemTextFormatter: function (item) {
          return 'Within: ' + item.value + (item.value > 1 ? ' miles' : ' mile');
        },
        exec: function (value, term) {
          var filter = this;

          if (value) {
            if (!filter.appliedDate) {
              var conditions = $location.search();

              if (!conditions[filter.date_term]) {
                var dateStr = moment().startOf('day').format('YYYY-MM-DD');
                filter._super(dateStr, filter.date_term);
                filter.appliedDate = true;
              }
            }

            filter._super(value, term);
          } else {
            filter.clearSelection();
            filter.appliedDate = false;
          }
        },
        init: function () {
          this._super();

          angular.extend(this, {
            name: 'TargetAudienceFilter',
            label: 'Distance',
            term: 'distance',
            mode: 'numberChoice',
            date_term: 'min_start_date',
            //For backward compatibility
            multiChoice: false,
            value: null,
            dependency: [{
              filter: self.ObjectFilter,
              condition: 'course'
            },
            function () {
              return !currentUser.get().anonymous;
            }]
          });
        }
      });

      /**
     * @description
     * Course Subject areas filter. Doesn't represents UI filter. Filter options loads from WS on demand when
     * subject area filter exists in the query string.
     * @type {*|any|void|Object}
     */
      this.SubjectFilter = this.SearchFilterBase.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            name: 'SubjectFilter',
            term: 'subject',
            label: 'Subject',
            mode: 'multiChoice',
            //For backward compatibility
            multiChoice: true,
            items: [],
            dependency: {
              filter: self.ObjectFilter,
              condition: 'course'
            }
          });
        },
        load: function () {
          return courseService.getCourseOptions().then(function (options) {
            var list = [];
            _.forEach(options.subjectAreas, function (item) {
              if (item.topics) {
                list = _.union(list, item.topics);
              }
            });

            this.items = _.map(list, function (item) {
              return {
                id: item.id,
                text: item.name,
                value: item.id + ''
              };
            });

            this._initSelectedItems();
          }.bind(this));
        }
      });

      /**
     * @description
     * Course rating filter.
     * This filter depends on the ObjectFilter and available only when "courses" selected
     * @type {*|any|void|Object}
     */
      this.CourseRatingFilter = this.SearchFilterBase.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            name: 'CourseRatingFilter',
            label: 'Course Rating',
            term: 'rating',
            mode: 'singleChoice',
            //For backward compatibility
            multiChoice: false,
            items: [
              {text: '', value: '4'},
              {text: '', value: '3'},
              {text: '', value: '2'},
              {text: '', value: '1'}
            ],
            dependency: [{
              filter: self.ObjectFilter,
              condition: 'course'
            },
            function () {
              return _.get($window, 'elmsEnvironment.userSettings.course_rating_available', false) &&
                _.get(globalConfig.settings, 'courseDetails.reviewsVisible');
            }
            ]
          });
        }
      });

      /**
     * @description
     * Object filter.
     * @type {*|any|void|Object}
     */
      this.ShortcutsFilter = this.SearchFilterBase.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            name: 'ShortcutsFilter',
            label: 'Shortcuts',
            term: 'shortcut',
            mode: 'singleChoice',
            //For backward compatibility
            multiChoice: false,
            open: true,
            items: [
              {text: 'Certifications', link: {tag: 'academy certification', sort: 'newest'}},
            ]
          });
        }
      });

      this.MultipleTermFilter = this.SearchFilterBase.extend({
        _initSelectedItems: function () {
          var conditions = $location.search();
          this.items = _.map(this.items, function (item) {
            return angular.extend(item, {selected: _.includes(conditions[item.term || this.term] && (conditions[item.term || this.term] + '').split(',') || [], item.value)});
          }.bind(this));

          this.selectedItems = _.filter(this.items, 'selected');
          this._setVisibility();
        },
        clearSelection: function () {
          _.forEach(this.selectedItems, function (item) {
            this.exec(item.value, item.term);
          }.bind(this));
        }
      });

      this.CoursePriceFilter = this.MultipleTermFilter.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            name: 'CoursePriceFilter',
            label: 'Course Price',
            objectName: 'course',
            mode: 'priceChoice',
            //For backward compatibility
            multiChoice: false,
            items: [
              {text: 'Price From', term: 'min_price', value: ''},
              {text: 'To', term: 'max_price', value: ''},
              {text: 'Free', term: 'free', value: false}
            ],
            dependency: [{
              filter: self.ObjectFilter,
              condition: 'course'
            }, function () {
              return _.get(globalConfig.settings, 'ecommerceEnabled');
            }
            ]
          });
        },
        restoreDefaults: function (){
          this.items[0].value = '';
          this.items[1].value = '';
          this.items[2].value = false;
        },
        itemTextFormatter: function (item) {
          if (item) {
            if (item.term !== 'free'){
              return [item.text, ': $', item.value].join('');
            } else {
              return item.text;
            }
          }
        },
        _initSelectedItems: function () {
          var conditions = $location.search();
          if (conditions.min_price){
            this.items[0].value = Number(conditions.min_price);
          }

          if (conditions.max_price) {
            this.items[1].value = Number(conditions.max_price);
          }

          if (conditions.free === 'true') {
            this.items[2].value = true;
          }

          this.selectedItems = _.filter(this.items, function (item) {
            return item.value || item.value === 0;
          });
          this._setVisibility();
        },
        exec: function (value, term) {
          if (value === null){
            value = '';
          }
          this._super(value, term);
        }
      });

      this.CommonFilter = this.MultipleTermFilter.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            name: 'CommonFilter',
            label: 'Common Filters',
            mode: 'multiChoice',
            //For backward compatibility
            multiChoice: true,
            items: [
              {text: 'Free Courses', term: 'max_list_price', value: '0'},
              {text: 'Recently Reviewed', term: 'last_reviewed', value: '3months'},
              {text: 'Certificate Available', term: 'course_certificate_available', value: 'true'}
            ],
            dependency: {
              filter: self.ObjectFilter,
              condition: 'course'
            }
          });
        }
      });

      this.ScheduleFilter = this.MultipleTermFilter.extend({
        _initSelectedItems: function () {
          var conditions = $location.search();
          this.items = _.map(this.items, function (item) {
            return angular.extend(item, {
              value: conditions[item.term] ?
                new Date(Date.parse(conditions[item.term]) + (6e4 * new Date(Date.parse(conditions[item.term])).getTimezoneOffset())) :
                conditions[item.term]
            });
          });

          this.selectedItems = _.filter(this.items, 'value');
          this._setVisibility();
        },
        exec: function (value, term) {
          var dateStr = angular.isDate(value) ? moment(value).format('YYYY-MM-DD') : '';
          this._super(dateStr, term);
        },
        init: function () {
          this._super();

          angular.extend(this, {
            name: 'ScheduleFilter',
            label: 'Schedule Date',
            mode: 'datesChoice',
            //For backward compatibility
            multiChoice: false,
            itemTextFormatter: function (item) {
              return [item.text, ': ', moment(item.value).format('MM/DD/YYYY')].join('');
            },
            items: [
              {text: 'From Date', term: 'min_start_date', value: ''},
              {text: 'To Date', term: 'max_start_date', value: ''}
            ],
            dependency: {
              filter: self.ObjectFilter,
              condition: ['course', 'nonTrainEvent']
            }
          });
        }
      });

      this.UpdatedFilter = this.MultipleTermFilter.extend({
        _initSelectedItems: function () {
          var conditions = $location.search();
          this.items = _.map(this.items, function (item) {
            return angular.extend(item, {
              value: conditions[item.term] ?
                new Date(Date.parse(conditions[item.term]) + (6e4 * new Date(Date.parse(conditions[item.term])).getTimezoneOffset())) :
                conditions[item.term]
            });
          });

          this.selectedItems = _.filter(this.items, 'value');
          this._setVisibility();
        },
        exec: function (value, term) {
          var dateStr = angular.isDate(value) ? moment(value).format('YYYY-MM-DD') : '';
          this._super(dateStr, term);
        },
        init: function () {
          this._super();

          angular.extend(this, {
            name: 'UpdatedFilter',
            label: 'Updated Date',
            mode: 'datesChoice',
            //For backward compatibility
            multiChoice: false,
            itemTextFormatter: function (item) {
              return [item.text, ': ', moment(item.value).format('MM/DD/YYYY')].join('');
            },
            items: [
              {text: 'Updated From Date', term: 'min_updated_date', value: ''},
              {text: 'Updated To Date', term: 'max_updated_date', value: ''}
            ],
            dependency: {
              filter: self.ObjectFilter,
              condition: ['course', 'nonTrainEvent']
            }
          });
        }
      });
      /**
     * @description
     * Filter by the object created date
     * @type {*|any|void|Object}
     */
      this.ObjectFilter = this.SearchFilterBase.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            name: 'ObjectFilter',
            label: 'Object Type',
            term: 'type',
            mode: 'singleChoice',
            //For backward compatibility
            multiChoice: false,
            open: true,
            items: currentUser.get().anonymous ? [
              {text: 'Authors', value: 'author'},
              {text: 'Blogs', value: 'blog'},
              {text: 'Courses', value: 'course'},
              {text: 'eKnowledge', value: 'resource'},
            ] : [
              {text: 'Authors', value: 'author'},
              {text: 'Blogs', value: 'blog'},
              {text: 'Courses', value: 'course'},
              {text: 'eKnowledge', value: 'resource'},
              {text: 'Profiles', value: 'user'}
            ]
          });
        }
      });
      /**
     * @description
     * Filter by the object created date
     * @type {*|any|void|Object}
     */
      this.TimeRangeFilter = this.SearchFilterBase.extend({
        init: function () {
          this._super();

          angular.extend(this, {
            name: 'TimeRangeFilter',
            label: 'Time Range',
            term: 'created_date',
            mode: 'singleChoice',
            //For backward compatibility
            multiChoice: false,
            items: [
              {text: 'Today', value: 'today'},
              {text: 'Past 2 days', value: '2days'},
              {text: 'Past week', value: 'week'},
              {text: 'Past month', value: 'month'},
              {text: 'Past 3 months', value: '3months'},
              {text: 'Past 6 months', value: '6months'},
              {text: 'Past year', value: 'year'}
            ]
          });
        }
      });

      /**
     * @description
     * Object tags filter.
     * @type {*|any|void|Object}
     */
      this.TagFilter = this.SearchFilterBase.extend({
        init: function (attrs) {
          this._super();

          angular.extend(this, {
            name: 'TagFilter',
            label: 'Tag',
            term: 'tag',
            mode: 'singleChoice',
            //For backward compatibility
            multiChoice: false,
            items: []
          });

          angular.extend(this, attrs);
        },
        _initSelectedItems: function () {
          this._super();
          this.selectedItems = _.map(this.selectedOptions, function (item) {
            return {
              value: item,
              text: item
            };
          });
        }
      });

      this.clearAllFilters = function () {
        _.forEach(filters.all, function (filter) {
          filter.clearSelection();
        });
      };

      this.onInit = function (filtersInitFn) {
        this.initFn = filtersInitFn;
      };

      this._init = function () {
        if (this.initFn) {
          angular.extend(filters, this.initFn());
        }
      };

      this.loadFilters = function () {
        this._init();

        _.forEach(filters.all, function (filter) {
          filter.load();
        });

        return filters;
      };

      /**
     * @description
     * Extend base filter object with provided custom filter options.
     * Init and return custom filter object.
     * @param filter
     */
      this.getFilter = function (filter, options) {
        var baseFilter;

        baseFilter = searchFilterBase.get();
        angular.extend(baseFilter, filter.getOptions(options));

        EventDispatcher.call(baseFilter);
        baseFilter.init();

        return baseFilter;
      };

      /**
     * @description
     * Set dependency between two filters
     * @param parent
     * @param child
     */
      this.associateFilters = function (parent, child) {
        var dependencies = _.isArray(child.dependency) ? child.dependency : [child.dependency];

        parent.on('filtersChanged', function () {
          if (child.reload) {
            child.reload();
          }
        });

        dependencies.forEach(function (dep) {
          if (typeof dep === 'object') {
            angular.extend(dep, {
              filter: parent
            });
          }
        });
      };


      this.createFilter = function (name) {
        return new this[name]();
      };
    });
})();
