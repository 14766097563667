(function () {

  /**
   * @ngdoc service
   * @name kmi.lms.quiz.service:Quiz
   * @description
   * Represents Quiz data model.
   * Implements all server's CRUD methods for quizzes.
   */
  angular.module('kmi.lms.quiz')
    .factory('Quiz', quizService);

  /* @ngInject */
  function quizService($resource, uuid) {
    var Quiz = $resource('/a/quizzes/:quizId/', {quizId: '@id'}, {
      get: {
        method: 'GET',
        /**
         * @description
         * Transform response required to extend data with the default values.
         * @param data
         * @param headers
         * @param status
         * @returns {*}
         */
        transformResponse: function (data, headers, status) {
          if (status === 200) {
            return angular.extend(Quiz.defaults, angular.fromJson(data));
          }

          //When status is not 200 response body may contain error message or some useful data.
          if (headers('Content-Type') === 'application/json') {
            return angular.fromJson(data);
          }
          return data;
        }
      },
      query: {
        method: 'GET',
        params: {courseId: '@courseId'},
        url: '/a/quizzes/:courseId/quizzes/',
        isArray: true
      },
      update: {
        method: 'PUT'
      },
      clone: {
        method: 'POST',
        params: {clone: true},
        hasBody: false
      }
    }, {
      stripTrailingSlashes: false
    });


    Object.defineProperty(Quiz, 'defaults', {
      get: function () {
        return {
          /**
           * This ID required to store temporary data on the server when quiz is not saved yet.
           * For example question images which uploads immediately on select.
           */
          globalId: uuid.v4(),
          passingScoreRequired: false,
          resultsReviewAllowed: true,
          showCorrectAnswers: 0,
          resumeAllowed: false,
          active: true,
          questionDeliveryTypeId: 1,
          questions: [],
          resultTypeId: 1,
          scoreTypeId: 1
        };
      }
    });

    Quiz.newQuiz = function (attrs) {
      return new Quiz(angular.extend(Quiz.defaults, attrs || {}));
    };

    return Quiz;
  }
})();
