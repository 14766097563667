(function () {

  angular.module('kmi.lms.search.common')
    .component('collapsableSearchBar', {
      template: require('ajs/modules/search/components/collapsable-search-bar.html').default,
      controller: SearchBarController,
      controllerAs: 'vm',
      bindings: {
        title: '@?',
      }
    });

  /* @ngInject */
  function SearchBarController($state, $element, $timeout) {
    var vm = this;

    vm.search = search;
    vm.toggleSearchBar = toggleSearchBar;

    function search($event) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }

      $state.go('main.search', {query: vm.searchQuery});
      vm.searchQuery = '';
    }

    function toggleSearchBar() {
      vm.searchExpand = !vm.searchExpand;

      if (vm.searchExpand) {
        $timeout(function () {
          var controls = $element.find('#app_header_search_bar_input');
          if (controls && controls.length) {
            controls[0].focus();
          }
        });
      }
    }
  }
})();
