(function () {

  angular.module('kmi.lms.user.components')
    .directive('editUserAvatarNew', editUserAvatar);

  function editUserAvatar() {
    return {
      restrict: 'EA',
      scope: {
        user: '='
      },
      template: require('ajs/modules/user/edit/components/user-avatar-new.html').default,
      controller: EditUserAvatarController,
      controllerAs: 'vm',
      bindToController: true
    };
  }

  /* @ngInject */
  function EditUserAvatarController(fileUploadConfig, urlVersionService) {
    var vm = this;

    vm.$onInit = onInit;
    vm.resetFile = resetFile;
    vm.uploadProgressEventHandler = uploadProgressEventHandler;
    vm.uploadErrorHandler = uploadErrorHandler;

    function resetFile() {
      vm.file = null;
      vm.user.picture = null;
      urlVersionService.update();
      vm.userPictureForm.$setDirty();
    }

    function uploadProgressEventHandler(loaded, total) {
      vm.uploadProgress = (loaded / total) * 100;
    }

    function uploadErrorHandler(errorMessage) {
      vm.uploadError = errorMessage;
    }

    function onInit() {
      vm.uploadOptions = {
        endPoint: '/a/user/media/' + vm.user.globalId + '/temp_image/',
        maxFileSize: fileUploadConfig.image.maxFileSize
      };
      vm.imageFileMaxSizeStr = fileUploadConfig.image.maxFileSize;
    }
  }
})();
