(function () {

  angular
    .module('kmi.lms.user.learning')
    .component('userDashboardTile', {
      template: require('ajs/modules/user/learning/dashboard/dashboard-tile.html').default,
      controller: UserDashboardTileController,
      controllerAs: 'vm',
      bindings: {
        user: '<',
        card: '<',
        cardData: '=',
        level: '<',
        hasBackground: '<'
      }
    });

  /* @ngInject */
  function UserDashboardTileController($state) {
    var vm = this;

    vm.goToCard = goToCard;

    function goToCard(card) {
      $state.go('main.learningDashboardCard', {selected: card.name});
    }
  }
})();
