import {Subject} from 'rxjs';

(function () {

  angular.module('kmi.lms.user.registration')
    .component('selfRegistrationGroupsSelectionStep', {
      template: require('ajs/modules/user/registration/components/self-registration-groups-selection-step.html').default,
      controller: SelfRegistrationGroupsSelection,
      controllerAs: 'vm',
      bindings: {
        step: '<',
        user: '<',
        groupsChangeSubject: '<'
      }
    });

  /* @ngInject */
  function SelfRegistrationGroupsSelection() {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.groupsListChangedSubject = new Subject();
      vm.groupsListChangedSubject.subscribe((groups)=>{
        vm.user.groups = groups;
        vm.groupsChangeSubject.next(vm.user);
      });
    }
  }
})();
