(function () {

  angular.module('kmi.lms.user.network', [
    'ui.router',
    'kmi.lms.core',
    'kmi.lms.network'
  ]);
//     .config(moduleConfig);
//
//   /* @ngInject */
//   function moduleConfig($stateProvider){
//
//     /* @ngInject */
//     function userResolver ($transition$, currentUser, User, $q) {
//       var selfUser = currentUser.get();
//
//       if ($transition$.params().userId && parseInt($transition$.params().userId, 10) === selfUser.id || !$transition$.params().userId) {
//         return selfUser;
//       } else {
//         return User.get($transition$.params().userId).then(function (user) {
//           if (user.userNetwork && user.userNetwork.profile && user.userNetwork.profile.sharingEnabled) {
//             return user;
//           } else {
//             return $q.reject({status: 403, data: 'This user is not sharing the profile.'});
//           }
//         }, function (reason) {
//           if (reason.status === 403) {
//             return $q.reject({status: 403, data: 'This user is not sharing the profile.'});
//           }
//
//           return $q.reject(reason);
//         });
//       }
//     }
//
//     $stateProvider
//       .state('main.user_network', {
//         url: '/community/{userId}',
//         component: 'userNetworkComponent',
//         data: {
//           label: 'Community',
//           availableFor: ['regularUser', 'admin']
//         },
//         params: {
//           userId: null
//         },
//         resolve: {user: userResolver}
//       });
//   }
})();
