(function () {

  angular.module('kmi.lms.search.common')
    .service('searchFilterCourseLabel', searchFilterCourseLabel);

  /* @ngInject */
  function searchFilterCourseLabel(courseService, _) {
    var service = {
      getOptions: getOptions
    };
    return service;

    ////////////

    function getOptions(options) {
      return angular.extend({
        term: 'label_id',
        label: 'Format Label',
        mode: 'multiChoice',
        items: [],
        dependency: {
          condition: 'course'
        },
        unassignedOption: {
          text: 'Unassigned',
          value: 'unassigned'
        },
        load: function () {
          return courseService.getCourseOptions().then(function (options) {
            this.items = _.map(_.filter(options.labels, {active: true}), function (item) {
              return {
                id: item.id,
                text: item.name,
                value: item.id + ''//cast to string
              };
            });

            this._initSelectedItems();
          }.bind(this));
        }
      }, options);
    }
  }
})();
