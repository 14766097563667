(function () {

  angular.module('kmi.lms.user')
    .component('userAvatar', {
      template: require('ajs/modules/user/view/components/user-avatar.html').default,
      controller: UserAvatarController,
      controllerAs: 'vm',
      bindings: {
        user: '=',
        size: '<?',
        skipDefault: '<?',
        showInitials: '<?',
        hideTitle: '<?',
        isTransparent:'<?'
      }
    });

  /* @ngInject */
  function UserAvatarController($scope, rootScopeService, globalConfig, $filter) {
    var vm = this;

    vm.avatarSettings = globalConfig.settings.avatarSettings || {};
    vm.$onInit = onInit;

    function onInit() {
      vm.initials = getInitials();

      if (vm.avatarSettings.randomBackgroundColor) {
        vm.setValue = parseInt(vm.user.id, 10) % 3;
      } else {
        vm.setValue = '-default';
      }

      loadAvatarImage();

      rootScopeService.on('event:urlVersion.updated', function f() {
        loadAvatarImage();
      });

      $scope.$watch('vm.user.id', function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          loadAvatarImage();
        }
      });
    }

    function loadAvatarImage() {
      if (!vm.user || !vm.user.id) {
        return;
      }

      var img = new Image();
      var avatarUrl = $filter('urlVersion')([
        '/a/user/', vm.user.id,
        '/avatar/?skip_default=', ((vm.skipDefault !== undefined ? vm.skipDefault : vm.avatarSettings.skipDefault) ? 'true' : 'false'),
        '&generate=', (vm.avatarSettings.generate ? 'true' : 'false')
      ].join(''));

      img.onload = function () {

        $scope.$evalAsync(function () {
          if (img.width > 1) {
            vm.initials = null;
            vm.backgroundImageUrl = ['url(', avatarUrl, ')'].join('');
            vm.backgroundColor = 'transparent';
          } else {
            vm.initials = getInitials();
            vm.backgroundImageUrl = null;
            vm.backgroundColor = null;
          }
        });

      };

      img.src = avatarUrl;
    }

    function getInitials() {
      if (vm.showInitials === false || !vm.avatarSettings.showInitials) {
        return null;
      }

      if (vm.user.firstName) {
        return [vm.user.firstName[0], vm.user.lastName[0]].join('').toUpperCase();
      }
      else if (vm.user.title) {
        return vm.user.title.split(' ').map(function (part) {
          return part[0];
        }).slice(0, 2).join('').toUpperCase();
      } else {
        return 'AA';
      }
    }
  }

})();
