(function () {

  angular
    .module('kmi.lms.user.mail', ['kmi.lms.core'])
    .config(moduleConfig)
    .run(moduleRun)
    .constant('userEmailOptInStatuses', {
      emailConfirmationPending: 1,
      noEmail: 2,
      sendMail: 3
    });

  /* @ngInject */
  function moduleConfig($stateProvider) {
    $stateProvider
      .state('prompt.emailConfirmed', {
        url: '/email_confirmed',
        component: 'emailConfirmedComponent',
        data: {
          label: 'Email confirmed',
          availableFor: ['anonymous', 'regularUser', 'admin']
        }
      })
      .state('prompt.unsubscribe', {
        url: '/unsubscribe?unsubscribeCode',
        params: {
          unsubscribeCode: null
        },
        component: 'unsubscribeComponent',
        data: {
          label: 'Email confirmed',
          availableFor: ['anonymous', 'regularUser', 'admin']
        }
      });
  }

  function moduleRun(globalConfig) {
    globalConfig.settings.authorizedStates.push({
      name: 'prompt.emailConfirmed',
      params: {}
    }, {
      name: 'prompt.unsubscribe',
      params: {}
    });
    globalConfig.settings.deepLinkingExcludedStates.push('prompt.emailConfirmed', 'prompt.unsubscribe');
  }
})();
