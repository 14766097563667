(function () {

  angular.module('kmi.lms.user.common')
    .factory('userTimeZonesService', function (geoService, moment, _) {
      return {
        populateDefaultTimeZone: populateDefaultTimeZone
      };

      function populateDefaultTimeZone(user) {
        if (user.timeZoneId) {
          return;
        }

        geoService.getTimeZones().toPromise()
          .then((timeZones) => {
            const userLocalTimeZone = _.find(timeZones, {tzId: moment.tz.guess()});
            const defaultZone = _.find(timeZones, {'default': true});

            if (userLocalTimeZone) {
              user.timeZoneId = userLocalTimeZone.id;
            } else if (defaultZone) {
              user.timeZoneId = defaultZone.id;
            }
          });
      }
    });
})();
