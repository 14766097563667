(function () {

  angular.module('kmi.lms.user.common')
    .factory('userAttributesService', userAttributesService);

  /* @ngInject */
  function userAttributesService($http, apiUrl) {

    var baseUrl = '/a/user/attributes',
      validateUrl = apiUrl(baseUrl + '/validate/{attr_type_name}/');

    return {
      validateAttributeTypeValue: validateAttributeTypeValue,
      getAttributeValues: getAttributeValues,
      save: save,
      getAttributeCategories: getAttributeCategories
    };


    function validateAttributeTypeValue(attr, options) {
      if (!attr || !attr.name || !attr.value) {
        return false;
      }
      validateUrl = apiUrl(validateUrl.replace('{attr_type_name}', attr.name));
      return $http.post(validateUrl, {value: attr.value}, options)
        .then(function (response) {
          return response.data;
        });
    }

    function getAttributeValues(userId, query) {
      return $http.get(apiUrl('/a/user/' + userId + '/attributes/'), {params: {query: query}}).then(function (response) {
        return response.data;
      });
    }

    function save(userId, attributes) {
      return $http.post('/a/user/' + userId + '/attributes/', {attributes: attributes});
    }

    function getAttributeCategories() {
      return $http.get('/a/user/attributes/categories/')
        .then(function (response) {
          return response.data;
        });
    }
  }

})();
