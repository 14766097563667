(function(){

  /**
   * @ngdoc service
   * @name kmi.lms.user.mail.service:userEmailOptInUnsubscribeService
   *
   *
   * @description
   * Provide Unsubscribe functionality
   * Implements all server's Unsubscribe methods.
   */

  angular.module('kmi.lms.user.mail')
    .factory('userEmailOptInUnsubscribeService', userEmailOptInUnsubscribeService);

  /* @ngInject */
  function userEmailOptInUnsubscribeService($http, apiUrl){
    var baseApiUrl = '/a/user/opt_in/unsubscribe/';

    return {
      get: get,
      unsubscribe: unsubscribe
    };

    function get(unsubscribeCode) {
      return $http.get(apiUrl(baseApiUrl), {
        'params': {
          'unsubscribeCode': unsubscribeCode
        }
      }).then(function(response){
        return response.data;
      });
    }

    function unsubscribe(unsubscribeCode) {
      return $http.post(apiUrl(baseApiUrl), {
        'unsubscribeCode': unsubscribeCode
      });
    }
  }
})();
