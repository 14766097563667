(function (){
  angular.module('kmi.lms.user.common')
    .component('userLastNameInput', {
      template: require('ajs/modules/user/components/user-lastname-input.html').default,
      controller: UserLastNameInputController,
      controllerAs: 'vm',
      bindings: {
        user: '=',
        formSubmitted: '=',
      }
    });

  function UserLastNameInputController(){
    const vm = this;

    vm.$onInit = onInit;
    function onInit(){}
  }
})();
