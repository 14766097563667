(function () {

  angular
    .module('kmi.lms.user.learning')
    .value('userLearningDashboardCardSettings', {
      customColumns: ['myTarget', 'dueDate', 'completed', 'expired', 'accessExpired', 'status', 'actions', 'credits'],
      itemsPerPage: 20,
      certificatesShowCe: false
    })
    .factory('userLearningDashboardService', userLearningDashboardService);

  /* @ngInject */
  function userLearningDashboardService($http, userLearningDashboardCardSettings, globalConfig, moment,
    $httpParamSerializer, $window, _) {
    return {
      getDashboard: getDashboard,
      getSchedule: getSchedule,
      isColumnAvailableForView: isColumnAvailableForView,
      exportCourses: exportCourses
    };

    function exportCourses(name, userId, options) {
      var fileName = (options && options.fileName) ||
        ((name || 'dashboard') + '_' + moment(new Date()).format('YYYY-MM-DD'));
      var format = (options && options.format) || 'csv';
      var params = {
        format: format,
        file_name: fileName
      };

      if (options) {
        angular.extend(params, options);
      }

      $window.location.assign(['/a/user/', userId, '/learning-dashboard/?', $httpParamSerializer(params)].join(''));
    }

    function getDashboard(userId, parameters) {
      return $http.get(['/a/user/', userId, '/learning-dashboard/'].join(''), {params: parameters})
        .then(function (response) {
          return response.data;
        });
    }

    function getSchedule(userId) {
      return $http.get(['/a/user/', userId, '/learning-schedule/'].join(''))
        .then(function (response) {
          return response.data;
        });
    }

    function isColumnAvailableForView(column, currentCard, section) {
      const cardNameToColumnsMap = {
        'completedExpired': ['expired', 'actions'],
        'incompleteExpired': ['accessExpired', 'actions'],
        'transcript': ['completed', 'status', 'actions', 'credits'],
        'certificates': ['completed', 'actions']
      };

      if (!isCustomColumn(column)) {
        return false;
      }

      if (column === 'credits' && !_.get($window, 'elmsEnvironment.settings.creditTypeSelectionEnabled', false)) {
        return false;
      }

      if (isInProgressCard(currentCard)) {
        return isInProgressColumn(column, section);
      }

      return cardNameToColumnsMap[currentCard.name]?.includes(column) || false;
    }

    function isCustomColumn(column) {
      return _.includes(userLearningDashboardCardSettings.customColumns, column);
    }

    function isInProgressCard(currentCard) {
      const inProgressCardNames = [
        'incomplete', 'mandatory', 'collections',
        'saved', 'evaluationPending', 'dueDate'
      ];
      return _.includes(inProgressCardNames, currentCard.name);
    }

    function isInProgressColumn(column, section) {
      const inProgressColumns = ['status', 'actions', 'myTarget', 'accessExpired', 'credits'];
      return _.includes(inProgressColumns, column) ||
        (section.hasOwnProperty('customColumns') && section.customColumns &&
          _.includes(section.customColumns, column));
    }
  }
})();
