(function () {

  angular.module('kmi.lms.search.common')
    .service('searchFilterCourseProviders', searchFilterCourseProviders);

  /* @ngInject */
  function searchFilterCourseProviders(currentUser, CourseProviderOrganization, _, CourseProviderOrganizationMember,
    User) {
    return {
      getOrganizationFilterConfig: getOrganizationFilterConfig,
      getOrganizationUserFilter: getOrganizationUserFilter,
      getCourseCreatorFilter: getCourseCreatorFilter,
      getNationalCpFilter: getNationalCpFilter,
      getMyOrganizationFilter: getMyOrganizationFilter
    };

    function getNationalCpFilter(options) {
      return {
        getOptions: getOptions
      };

      function getOptions() {
        return angular.extend({
          term: 'national_cp_only',
          label: 'National CPs only',
          mode: 'booleanFilter',
          items: [{
            text: 'National CPs only',
            value: 'true'
          }],
          dependency: [{
            condition: 'course_provider'
          }, function () {
            var user = currentUser.get();
            if (user.roles && user.roles.length) {
              return !!_.find(user.roles, function (role) {
                return role.roleId === 1 || //Elms Admin
                  role.roleId === 21 && role.groupId === 1; // National Course Provider Approver
              });
            }

            return false;
          }]
        }, options);
      }
    }

    function getCourseCreatorFilter(options) {
      return {
        getOptions: getOptions
      };

      function getOptions() {
        var user = currentUser.get();

        return angular.extend({
          term: 'created_by_user',
          label: 'My Courses Only',
          mode: 'booleanFilter',
          items: [{
            text: 'My Courses Only',
            value: 'me'
          }],
          dependency: [{
            condition: 'course'
          }, function () {
            if (user.roles && user.roles.length) {
              return !!_.find(user.roles, function (role) {
                return role.roleId === 50; // Local Course Provider
              });
            }

            return false;
          }],
          _initSelectedItems: function () {
            var that = this;

            this.items = _.map(this.items, function (item) {
              return angular.extend(item, {selected: _.includes(that.selectedOptions || [], item.value)});
            });

            this.selectedItems = _.filter(this.items, 'selected');
            this._setVisibility();

            if (this.visible && this.selectedOptions && this.selectedOptions.length &&
              !_.includes(this.selectedOptions, user.id + '') && this.selectedOptions[0] !== 'me') {
              User.get(this.selectedOptions[0]).then(function (targetUser) {
                if (!_.find(this.selectedItems, {value: targetUser.id + ''})) {
                  this.selectedItems.push({
                    text: 'Created By: ' + targetUser.firstName + ' ' + targetUser.lastName,
                    value: targetUser.id + ''
                  });
                }
              }.bind(this));
            }
          }
        }, options);
      }
    }

    function getMyOrganizationFilter(options) {
      return {
        getOptions: getOptions
      };

      function getOptions() {
        var user = currentUser.get();

        return angular.extend({
          term: 'my_organization',
          label: 'My Organization Only',
          mode: 'booleanFilter',
          items: [{
            text: 'My Organization Only',
            value: 'true'
          }],
          dependency: [{
            condition: 'course'
          }, function () {
            if (user.roles && user.roles.length) {
              return !!_.find(user.roles, function (role) {
                return role.roleId === 50; // Local Course Provider
              });
            }

            return false;
          }],
          _initSelectedItems: function () {
            var that = this;

            this.items = _.map(this.items, function (item) {
              return angular.extend(item, {selected: _.includes(that.selectedOptions || [], item.value)});
            });

            this.selectedItems = _.filter(this.items, 'selected');
            this._setVisibility();
          }
        }, options);
      }
    }

    function getOrganizationFilterConfig(options) {
      return {
        getOptions: getOptions
      };

      function getOptions() {
        var user = currentUser.get();
        return angular.extend({
          term: 'cp_organization_id',
          label: 'Organization',
          placeholder: 'Type to refine (limit 50)',
          mode: 'promptingFilter',
          appearance: 'panel',
          promptType: 'provider_organization',
          items: [],
          dependency: [{
            condition: 'course'
          }, function () {
            if (options && options.rolesDependency && options.rolesDependency.length) {
              if (user.roles && user.roles.length) {
                return !!_.find(user.roles, function (role) {
                  return _.includes(options.rolesDependency, role.roleId); // Approval Course Provider
                });
              }

              return false;
            }

            return true;
          }],
          _initSelectedItems: function () {
            if (this.selectedOptions && this.selectedOptions.length && !this.items.length) {
              this.loading = CourseProviderOrganization.query({query: angular.toJson({id: this.selectedOptions})})
                .$promise
                .then(function (organizations) {
                  this.items = _.map(organizations, function (item) {
                    return {
                      id: item.id,
                      text: item.shortName || item.name,
                      value: item.id + ''
                    };
                  });

                  var missingItems = _.xor(this.selectedOptions, _.map(this.items, 'value'));

                  for (var i = 0; i < missingItems.length; i++) {
                    this.items.push({
                      id: missingItems[i],
                      text: 'Organization ' + missingItems[i],
                      value: missingItems[i]
                    });
                  }

                  this.selectedItems = this.items;

                  this._setVisibility();
                }.bind(this))
                .finally(function () {
                  this.loading = null;
                }.bind(this));
            } else {
              this.selectedItems = _.filter(this.items, function (item) {
                return _.includes(this.selectedOptions || [], item.value);
              }.bind(this));

              this._setVisibility();
            }
          }
        }, options);
      }

    }

    function getOrganizationUserFilter() {
      return {
        getOptions: getOptions
      };

      function getOptions() {
        return {
          term: 'provider_user_id',
          label: 'Providers',
          mode: 'multiChoice',
          items: [],
          dependency: {},
          _initSelectedItems: function () {
            if (this.dependency && !this.loading && this.dependency.filter && this.dependency.filter.selectedOptions &&
              this.dependency.filter.selectedOptions.length &&
              (!this.items.length || this.items[0].organizationId !== this.dependency.filter.selectedOptions[0])) {
              if (this.items.length) {
                this.clearSelection();
              }
              this.loading
                = CourseProviderOrganizationMember.query({organizationId: this.dependency.filter.selectedOptions[0]})
                  .$promise
                  .then(function (members) {
                    this.items = _.map(members, function (item) {
                      return {
                        id: item.user.id,
                        organizationId: item.organizationId + '',
                        text: item.user.firstName + ' ' + item.user.lastName,
                        value: item.user.id + '',
                        selected: _.includes(this.selectedOptions || [], item.user.id + '')
                      };
                    }.bind(this));

                    this.selectedItems = _.filter(this.items, {selected: true});
                    this._setVisibility();

                  }.bind(this))
                  .finally(function () {
                    this.loading = false;
                  }.bind(this));
            } else {
              this.selectedItems = _.filter(this.items, function (item) {
                return _.includes(this.selectedOptions || [], item.value);
              }.bind(this));
              this._setVisibility();
            }
          }
        };
      }
    }
  }
})();
