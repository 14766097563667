(function(){

  angular.module('kmi.lms.user.components')
    .component('editUserAccountInfo', {
      bindings: {
        user: '=',
        selfEdit: '=',
        formOptions: '='
      },
      template: require('ajs/modules/user/edit/components/account-information.html').default,
      controller: EditUserAccountInfoController,
      controllerAs: 'vm'
    });

  /* @ngInject*/
  function EditUserAccountInfoController(userPasswordService, genderService, globalConfig, _){
    var vm = this;
    vm.$onInit = onInit;

    vm.settings = _.get(globalConfig, 'settings.user');
    vm.resetPassword = resetPassword;
    vm.providePassword = providePassword;

    function onInit(){
      genderService.getGenderList().then(bindGenders);

      function bindGenders(genders){
        vm.genders = genders;
      }
    }

    function resetPassword(){
      if(vm.selfEdit){
        userPasswordService.changePasswordDialog(vm.user);
      }
      else{
        userPasswordService.resetPasswordDialog(vm.user);
      }
    }

    function providePassword(){
      userPasswordService.providePasswordDialog(vm.user);
    }
  }
})();
