(function () {

  angular.module('kmi.lms.search.common')
    .service('searchFilterCertificate', searchFilterCertificate);

  /* @ngInject */
  function searchFilterCertificate($injector, _) {
    return {
      getMyCertificatesFilterConfig: getMyCertificatesFilterConfig,
      getCertificateCoursesFilterConfig: getCertificateCoursesFilterConfig
    };

    function getMyCertificatesFilterConfig() {
      return {
        getOptions: getOptions
      };

      function getOptions() {
        return {
          label: 'My Certificates',
          term: 'created_by_user',
          mode: 'booleanFilter',
          items: [
            {text: 'Show only my certificates', value: 'true'}
          ],
          dependency: {
            condition: 'certificate'
          }
        };
      }
    }

    function getCertificateCoursesFilterConfig() {
      return {
        getOptions: getOptions
      };

      function getOptions() {
        return {
          label: 'Certificate',
          term: 'certificate',
          mode: 'singleChoice',
          items: [],
          dependency: {
            condition: 'course'
          },
          _initSelectedItems: function () {
            var that = this;

            if (!_.isEqual(this.selectedOptions, _.map(this.selectedItems, 'value'))) {
              this.items = [];
              this.selectedItems = [];

              _.forEach(this.selectedOptions, function (certificateId) {
                var certificate = $injector.get('Certificate');

                if (certificate) {
                  certificate.get({certificateId: certificateId}, function (certificate) {
                    that.items.push({
                      value: certificateId,
                      text: certificate.name || 'Certificate'
                    });

                    that.selectedItems = that.items;
                  }, function () {
                    that._addGenericItem();
                  });
                }
              });
            }

            this._setVisibility();
          }
        };
      }
    }

  }
})();
