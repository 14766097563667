(function () {

  angular
    .module('kmi.lms.user.learningSeries')
    .factory('userLearningSeriesService', userLearningSeriesService);

  function userLearningSeriesService($http){
    return {
      getLearningSeriesStructure: getLearningSeriesStructure
    };

    function getLearningSeriesStructure(userId, lsId){
      return $http.get(['/a/user/', userId, '/learning_series/' + lsId + '/structure/'].join(''))
        .then(function (response) {
          return response.data;
        });
    }
  }

})();
