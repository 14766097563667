(function () {
  angular.module('kmi.lms.user.common')
    .component('userStateSelection', {
      template: require('ajs/modules/user/components/user-state-selection.html').default,
      controller: UserStateSelectionController,
      controllerAs: 'vm',
      bindings: {
        user: '=',
        selfEdit: '<',
        settings: '=',
        formSubmitted: '=',
      }
    });

  function UserStateSelectionController($scope, geoService, _) {
    const vm = this;

    vm.isStateRequired = isStateRequired;
    vm.$onInit = onInit;

    function onInit() {
      bindStates();

      $scope.$watch('vm.user.country', function (newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          countryChange();
        }
      });
    }

    function bindStates() {
      if (vm.user.country) {
        geoService.getStates(vm.user.country).toPromise().then(function (states) {
          vm.states = states;
        });
      } else {
        vm.states = [];
      }
    }

    function isCountryRequired() {
      return vm.selfEdit && vm.settings && vm.settings['Users.CountryRequired'] &&
        vm.settings['Users.CountryRequired'].toLowerCase() === 'true';
    }

    function countryChange() {
      vm.user.stateId = null;
      vm.user.state = null;
      vm.user.stateName = null;
      bindStates();
    }

    function isStateRequired() {
      return vm.selfEdit && vm.states && vm.states.length && (vm.user.country === 'US' || isCountryRequired() && vm.settings && vm.settings['Users.StateRequired'] && vm.settings['Users.StateRequired'].toLowerCase() === 'true');
    }
  }
})();
