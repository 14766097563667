(function () {

  angular.module('kmi.lms.user.registration', [
    'ui.router',
    'http-auth-interceptor',
    'kmi.lms.core',
    'kmi.lms.user.components'
  ]);

})();
