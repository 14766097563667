(function () {

  angular
    .module('kmi.lms.user')
    .component('userProfileMenu', {
      template: require('ajs/modules/user/view/components/user-profile-menu.html').default,
      controller: UserProfileMenuController,
      controllerAs: 'vm',
      bindings: {
        user: '=',
        openHandler: '&onOpen',
      }
    });

  /* @ngInject */
  function UserProfileMenuController($scope, rootScopeService, $state, $q, userSettingsService, $injector,
    securityService, globalConfig) {
    var vm = this;

    vm.state = $state;
    vm.userSettingsService = userSettingsService;

    vm.$onInit = onInit;
    vm.showProfile = showProfile;
    vm.showNotifications = showNotifications;
    vm.settings = globalConfig.settings;
    vm.isStateAvailable = securityService.isStateAvailable;
    vm.signOutWording = globalConfig.signInWording.SignOut;

    function onInit() {
      updateNotifications();

      $scope.$on('event:notifications:updated', updateNotifications);

      $scope.$watch('vm.menuOpen', function (newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          vm.openHandler();
        }
      });
    }

    function updateNotifications() {
      var service = $injector.has('userNotificationsService') && $injector.get('userNotificationsService');
      if (service) {
        var notifications = service.getNotifications();
        vm.notificationsCount = notifications && notifications.length;
      }
    }

    function showProfile() {
      $state.go('edit.account');
    }

    function showNotifications() {
      rootScopeService.broadcast('event:notifications:alert:showNotifications');
    }
  }
})();
