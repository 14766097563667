(function (){
  angular.module('kmi.lms.user.common')
    .component('userPoliciesSignOff', {
      template: require('ajs/modules/user/components/user-policies-signoff.html').default,
      controller: UserPoliciesSignOffController,
      controllerAs: 'vm',
      bindings: {
        user: '=',
        formSubmitted: '=',
      }
    });

  function UserPoliciesSignOffController(policiesService){
    const vm = this;

    vm.$onInit = onInit;
    function onInit(){
      policiesService.getPolicies()
        .then(function (policies) {
          vm.policies = policies;
        });
    }
  }
})();
