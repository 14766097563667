(function () {

  angular.module('kmi.lms.user.certificates')
    .directive('externalCertificateFile', externalCertificateFile);

  function externalCertificateFile() {
    return {
      restrict: 'EA',
      scope: {
        certificate: '=',
        isEditable: '<?',
        formSubmitted: '=',
      },
      template: require('ajs/modules/user/certificates/external/components/certificate-file.html').default,
      controller: ExternalCertificateFileController,
      controllerAs: 'vm',
      bindToController: true
    };
  }

  /* @ngInject*/
  function ExternalCertificateFileController($scope, currentUser, _) {
    var vm = this, apiEndpoint;

    vm.thumbnail = false;

    vm.$onInit = onInit;

    function onInit() {
      apiEndpoint = ['/a/user/', currentUser.get().id, '/certificates/external/', vm.certificate.globalId,
        '/temp_certificate/?fullsize'].join('');

      vm.certificateUploadOptions = {
        url: apiEndpoint,
        availableThumbnail: ['gif', 'jpg', 'jpeg', 'png', 'bmp']
      };

      activate();
    }

    function activate() {
      if (_.has(vm.certificate, 'file.fileName')) {
        vm.thumbnail = thumbnailType(vm.certificate.file.fileName);
      }

      $scope.$watch('vm.certificateFile', onFileChanged);

      function onFileChanged(value, oldValue) {
        if (value === oldValue) {
          return;
        }

        if (vm.certificateFile) {
          vm.certificate.file = vm.certificateFile.tmpName;
          vm.thumbnail = thumbnailType(vm.certificateFile.tmpName);
        } else {
          vm.thumbnail = false;
          vm.certificate.file = null;
        }
      }
    }

    function thumbnailType(fileName) {
      var fileExtension = fileName.split('.').pop();
      if (_.includes(vm.certificateUploadOptions.availableThumbnail, fileExtension)) {
        return 'image';
      }
      return fileExtension === 'pdf' ? 'pdf' : false;
    }
  }
})();
