(function () {

  angular.module('kmi.lms.user.certificates')
    .directive('certificateFileUpload', function (certificateFileMaxSize) {
      return {
        restrict: 'EA',
        scope: {
          certificateFile: '=ngModel',
          certificateFileUpload: '=',
          formSubmitted: '='
        },
        template: require('ajs/modules/user/certificates/external/components/certificate-upload.html').default,
        link: {
          pre: function preLink(scope, element) {
            scope.model = {
              certificateFile: scope.certificateFile
            };

            scope.$watch('model.certificateFile', function () {
              scope.certificateFile = scope.model.certificateFile;
            });

            scope.options = {
              url: scope.certificateFileUpload.url,
              autoUpload: true,
              formData: scope.certificateFileUpload.formData,
              dropZone: element.find('.drop-zone'),
              maxNumberOfFiles: 1,
              disableImageMetaDataLoad: true,
              disableImageLoad: true,
              maxFileSize: certificateFileMaxSize * 1024 * 1024,
              acceptFileTypes: /(\.|\/)(gif|jpe?g|png|bmp|pdf)$/i,
              messages: {maxNumberOfFiles: 'Maximum number of files exceeded',
                acceptFileTypes: 'File type is not allowed. Allowed types: .gif, .jpeg, .jpg, .bmp, .png, .pdf.',
                maxFileSize: 'File is too large',
                minFileSize: 'File is too small'}
            };
          }
        }
      };
    });
})();
