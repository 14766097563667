(function () {
  angular.module('kmi.lms.user.common')
    .component('userTimeZoneInput', {
      template: require('ajs/modules/user/components/user-timezone-input.html').default,
      controller: UserTimeZoneInputController,
      controllerAs: 'vm',
      bindings: {
        user: '=',
        formSubmitted: '=',
      }
    });

  function UserTimeZoneInputController(moment, geoService, _) {
    const vm = this;

    vm.$onInit = onInit;

    function onInit() {
      geoService.getTimeZones().toPromise().then(bindTimeZones);
    }

    function bindTimeZones(timeZones) {
      vm.timeZones = timeZones;

      if (!vm.user.timeZoneId) {
        var userLocalTimeZone = _.find(timeZones, {tzId: moment.tz.guess()});
        var defaultZone = _.find(timeZones, {'default': true});

        if (userLocalTimeZone) {
          vm.user.timeZoneId = userLocalTimeZone.id;
        } else if (defaultZone) {
          vm.user.timeZoneId = defaultZone.id;
        }
      }
    }
  }
})();
