(function () {

  angular
    .module('kmi.lms.user.learningSeries')
    .component('userLearningSeriesStatus', {
      template: require('ajs/modules/user/learning-series/components/ls-user-status.html').default,
      bindings: {
        learningSeriesUser: '<'
      }
    });

})();
