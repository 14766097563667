(function () {

  angular.module('kmi.lms.user.notifications')
    .factory('userNotificationsService', userNotificationsService);

  /* @ngInject */
  function userNotificationsService(
    $http, apiUrl, $timeout, $uibModal, $cookies, rootScopeService, sjcl,
    kmiUserNotificationsViewed, globalConfig, _, sharedRequestService, cachedRequestService, currentUser,
    CourseRegistration, LearningObjectRegistration, LearningObjectRegistrationWorkflow, $q, $state, userEmailOptInService
  ) {
    const maxFastAttemptsNumber = 2, regularRefreshRate = 86400e3, fastRefreshRate = 10e3;
    var checkScheduled = null, checkInProgress = null,
      fastAttemptsNumber = 0, refreshRate = regularRefreshRate,
      notifications, identifier, viewedNotifications;

    return {
      check: check,
      excludeNotification: excludeNotification,
      showNotifications: showNotifications,
      getNotifications: getNotifications,
      assignNotificationActions: assignNotificationActions
    };

    function getKey() {
      return ['user_notifications', globalConfig.settings.view, currentUser.get().id].join('_');
    }

    function fetchNotifications() {
      var isAdminView = globalConfig.settings.view === 'admin';
      return $http.get(apiUrl(['/a/user/me/notifications/', isAdminView ? '?application=admin' : ''].join('')),
        {ignoreLoadingBar: true}).then(function (response) {
        return response.data;
      });
    }

    function getNotifications() {
      return notifications;
    }

    function check() {
      if (checkInProgress) {
        return;
      }

      if (checkScheduled) {
        // Cancel scheduled check if was secondary call earlier
        $timeout.cancel(checkScheduled);
      }

      var cachedRequest = cachedRequestService.init(getKey());
      checkInProgress = cachedRequest.wrap(function () {
        var sharedRequest = sharedRequestService.init(getKey());
        return sharedRequest.wrap(fetchNotifications);
      }, refreshRate)
        .then(function (data) {
          dataReceivedHandler(data);
        }).finally(function () {
          checkInProgress = null;
        });
    }

    function dataReceivedHandler(data) {
      if (data.actual) {
        identifier = data.identifier;

        // Get list of viewed (clicked) today notifications by current user
        var cookie = $cookies.get(kmiUserNotificationsViewed);
        var cookieVal = (cookie) ? angular.fromJson(sjcl.codec.utf8String.fromBits(sjcl.codec.base64.toBits(cookie)))
          : {identifier: '', viewedNotifications: []};

        if (cookieVal.identifier === data.identifier) {
          // If cookie record is for current user
          // Init "viewedNotifications" with value from cookie
          viewedNotifications = cookieVal.viewedNotifications;

          // Remove viewed notifications from full list of notifications from WS
          notifications = _.filter(data.notifications, function (n) {
            return !_.find(viewedNotifications, function (vn) {
              // Filter by notification "type" and "ObjectId" if it is
              return vn.type === n.type && (!vn.objectId || vn.objectId === _.get(n, 'object.id'));
            });
          });
        } else {
          // If cookie record is not for current user
          // Remove cookie record
          $cookies.remove(kmiUserNotificationsViewed);
          // Clear "viewedNotifications" list
          viewedNotifications = [];
          notifications = data.notifications;
        }

        // Broadcast event about notifications update with new list of notifications
        rootScopeService.broadcast('event:notifications:updated', {
          identifier: identifier,
          notifications: notifications
        });
        // Reset refresh rate to regular (24h)
        refreshRate = regularRefreshRate;
        // Reset count of fast attempts
        fastAttemptsNumber = 0;
      } else {
        // If data is not actual increment fast attempts number and  decrease refresh rate to fast (10 sec)
        fastAttemptsNumber++;
        refreshRate = fastRefreshRate;
        if (fastAttemptsNumber > maxFastAttemptsNumber) {
          // Cancel new requests
          return;
        }
      }
      // Schedule next WS call after "refreshRate" (regular - 24h, fast - 10 sec)
      checkScheduled = $timeout(check, refreshRate);
    }

    // Add viewed (clicked) notification to record in cookie
    function excludeNotification(notification) {
      var type = notification.type,
        objectId = _.get(notification, 'object.id');

      // Add viewed (clicked) notification to "viewedNotifications"
      viewedNotifications.push({
        type: type,
        objectId: objectId
      });

      // Prepare data for current user with "viewedNotifications"
      var today = new Date();
      var expires = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
      var cookieVal = {
        identifier: identifier,
        viewedNotifications: viewedNotifications
      };

      // Put prepared data to cookie
      $cookies.put(kmiUserNotificationsViewed,
        sjcl.codec.base64.fromBits(sjcl.codec.utf8String.toBits(angular.toJson(cookieVal))),
        {path: '/', expires: expires}
      );

      // Remove viewed notification from list of notifications
      notifications = _.filter(notifications, function (n) {
        // Filter by notification "type" and "ObjectId" if it is
        return type !== n.type || objectId && objectId !== _.get(n, 'object.id');
      });

      // Broadcast event about notifications update with new list of notifications
      rootScopeService.broadcast('event:notifications:updated', {
        identifier: identifier,
        notifications: notifications
      });
    }

    function showNotifications() {
      var modalInstance = $uibModal.open({
        component: 'userNotificationsModalComponent',
        resolve: {
          notifications: function () {
            return notifications;
          }
        }
      });

      modalInstance.result.then(function (viewedNotification) {
        excludeNotification(viewedNotification);
      });
    }

    function assignNotificationActions(notifications) {
      notifications.forEach(function (notification) {
        notification.action = function () {
          var actionFn = null;

          switch (notification.type) {
            case 'profileIncomplete': {
              actionFn = function () {
                return launchUrl('edit.account', null);
              };
              break;
            }
            case 'upcomingEvents': {
              actionFn = function () {
                return launchUrl('main.learning', {view: 'scheduledEvents'});
              };
              break;
            }
            case 'unfinishedAssessments': {
              actionFn = function () {
                return launchAction(notification.object.action, notification.object.id);
              };
              break;
            }
            case 'unfinishedPreAssessments': {
              actionFn = function () {
                return launchAction(notification.object.action, notification.object.id);
              };
              break;
            }
            case 'unfinishedEvaluations': {
              actionFn = function () {
                return launchAction(notification.object.action, notification.object.id);
              };
              break;
            }
            case 'unratedCourses': {
              actionFn = function () {
                return launchUrl('main.course', {id: notification.object.id, activeTab: 'reviews'});
              };
              break;
            }
            case 'pendingCourseCompletions': {
              actionFn = function () {
                return launchUrl('main.course', {id: notification.object.id});
              };
              break;
            }
            case 'emailOptIn': {
              actionFn = function () {
                if (notification.object.state === 'initial') {
                  return showOptInModal();
                }
                if (notification.object.state === 'noConfirmation') {
                  return launchUrl('edit.account', {activeView: 'mainDescriptionForm'});
                }
              };
              break;
            }
            case 'unfinishedSurveyCourses': {
              actionFn = function () {
                return launchUrl('main.course', {id: notification.object.id});
              };
              break;
            }
            case 'followUpEvaluations': {
              actionFn = function () {
                return launchAction(notification.object.action, notification.object.id);
              };
              break;
            }
            case 'registrationExpiration': {
              actionFn = function () {
                return launchUrl('main.course', {id: notification.object.id});
              };
              break;
            }
            case 'dueDate': {
              actionFn = function () {
                return launchUrl('main.course', {id: notification.object.id});
              };
              break;
            }
            case 'targetDate': {
              actionFn = function () {
                return launchUrl('main.course', {id: notification.object.id});
              };
              break;
            }
            case 'mandatoryTrainings': {
              actionFn = function () {
                return launchUrl('main.learning', {view: 'mandatory', statuses: 'all', target: 'my_training'});
              };
              break;
            }
            case 'userAchievementsAchieved': {
              actionFn = function () {
                return launchUrl('main.achievements', null);
              };
              break;
            }
            case 'userAchievementsAvailable': {
              actionFn = function () {
                return launchUrl('main.achievements', null);
              };
              break;
            }
            case 'passwordExpirationWarning': {
              actionFn = function () {
                return launchUrl('edit.account', null);
              };
              break;
            }
          }


          $q.when(actionFn && actionFn())
            .then(function () {
              if (globalConfig.settings && !globalConfig.settings.keepNotificationInView) {
                excludeNotification(notification);
              }
            });
        };
      });
    }

    function launchAction(action, registrationId) {
      return CourseRegistration.get({registrationId: registrationId}, function (registration) {
        var workflow;

        let courseRegistration = LearningObjectRegistration.getFromReg(registration);

        workflow = new LearningObjectRegistrationWorkflow(courseRegistration);

        if (workflow.hasAction(action)) {
          workflow.exec(action, {}, {
            skipPrerequisites: true
          });
        } else {
          launchUrl('main.course', {id: courseRegistration.course.id});
        }
      }).$promise;
    }

    function launchUrl(url, params) {
      return $state.go(url, params);
    }

    function showOptInModal() {
      return userEmailOptInService.showOptInModal(currentUser.get().email);
    }
  }
})();
