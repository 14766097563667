(function () {

  angular.module('kmi.lms.user.edit', [
    'kmi.lms.user.common',
    'kmi.lms.user.components',
    'kmi.lms.user.mail',
    'kmi.lms.components',
    'kmi.lms.customAttributes.common'
  ]);
})();
