(function () {

  angular.module('kmi.lms.search.common')
    .service('searchUserFilters', searchUserFilters);

  /* @ngInject */
  function searchUserFilters(Group, userRolesService, currentUser, _) {

    return {
      getGroupFilter: getGroupFilter,
      getRoleFilter: getRoleFilter,
      getIncludeSubgroupsFilter: getIncludeSubgroupsFilter
    };

    function getIncludeSubgroupsFilter(options) {
      return {
        getOptions: getOptions
      };

      function getOptions() {
        return angular.extend({
          term: 'include_subgroups',
          label: 'Search sub-groups',
          mode: 'booleanFilter',
          inverted: true,
          items: [{
            text: 'direct',
            value: 'false'
          }],
          dependency: {
            condition: 'user'
          },
        }, options);

      }

    }

    function getGroupFilter() {
      return {
        getOptions: getOptions
      };

      function getOptions() {
        return {
          term: 'group_id',
          label: 'Groups',
          mode: 'searchableMultiChoice',
          items: [],
          dependency: {
            condition: 'user'
          },
          getItemsPromise: function (searchQuery) {
            searchQuery = searchQuery || {};

            // Permission Manager, User Account Manager, Course Attendance Manager,
            // Batch Registration Manager, User Accounts View, Training Plan Manager
            angular.extend(searchQuery, {
              roles: [2, 3, 20, 90, 110, 270],
              childrenCheck: true
            });

            function adjustNodes(nodes) {
              if (!nodes) {
                return;
              }
              var i = nodes.length - 1;
              for (; i >= 0; i--) {
                if (nodes[i].nodes && nodes[i].nodes.length) {
                  adjustNodes(nodes[i].nodes);
                }
                nodes[i].text = nodes[i].name;
                nodes[i].value = nodes[i].id + '';
              }
            }

            return Group.query({query: angular.toJson(searchQuery)}).$promise.then(function (data) {
              return {
                itemCount: data.total,
                items: _.map(data.items, function (item) {
                  adjustNodes(item.nodes);
                  return {
                    id: item.id,
                    text: item.name,
                    value: item.id + '',//cast to string,
                    path: item.path,
                    nodes: item.nodes || [],
                    hasChildren: item.hasChildren,
                    expanded: item.expanded
                  };
                })
              };
            });
          },
          init: function () {
            this.extractConditions();

            this.getItemsPromise({group_ids: this.selectedOptions || []}).then(function (data) {
              this.items = data.items;

              this._initSelectedItems();
            }.bind(this));
          }
        };
      }
    }

    function getRoleFilter(options) {
      return {
        getOptions: getOptions
      };

      function getOptions() {
        return angular.extend({
          term: 'user_roles',
          label: 'Roles',
          mode: 'multiChoice',
          items: [],
          dependency: [{
            condition: 'user'
          }, function () {
            return currentUser.get().isInRole(1) || currentUser.get().isInRole(2);
          }],
          load: function () {
            return userRolesService.getRoles({include_invisible: true})
              .then(function (roles) {
                this.items = _.map(_.filter(roles, {'global': false}), function (item) {
                  return {
                    id: item.id,
                    text: item.name,
                    value: item.id + ''
                  };
                });

                this._initSelectedItems();
              }.bind(this));
          }
        }, options);
      }
    }
  }
})();
