import { GlobalConfig } from 'app/core/environment';
import { userRegistrationSteps } from './registration-steps';

export interface IRegistrationStepOptions {
  [key: string]: boolean;

  submitted: boolean;
  pending?: boolean;
}

export interface IRegistrationStep {
  id: string;
  title: string;
  next?: boolean;
  prev?: boolean;
  formGroup?: any;
  disabled?: boolean;
  options?: IRegistrationStepOptions
  _disabled: boolean;
}

export class UserRegistrationService {
  private current: IRegistrationStep;
  private steps: IRegistrationStep[] = [];
  private index: number;

  private availableSteps: string[] = [];

  static $inject = ['globalConfig'];

  constructor(globalConfig: GlobalConfig) {
    this.availableSteps = globalConfig.settings.registration?.steps || ['simpleUserRegistration'];
  }

  /**
   * Initialises all registration steps.
   * Return value: all available steps
   * */
  getAvailableSteps(): IRegistrationStep[] {
    this.index = 0;
    this.steps = [];
    this.availableSteps.forEach((step) => {
      const registrationStep = {...userRegistrationSteps[step]} as IRegistrationStep;
      registrationStep.options = { submitted: false };

      registrationStep._disabled = false;
      Object.defineProperty(registrationStep, 'disabled', {
        get: ()=> registrationStep._disabled,
        set: (value)=> {
          registrationStep._disabled = value;
          this.rebuild();
        }
      });

      this.steps.push(registrationStep);
    });
    this.rebuild();
    this.current = this.steps[this.index];

    return this.steps;
  }

  rebuild(){
    this.steps.forEach((step, index) => {
      step.prev = index > 0;
      step.next = this.availableSteps.find((s, i) => i > index && !this.steps[i]._disabled) !== undefined;
    });
  }

  /**
   * Return the current selected registration step
   * */
  get() {
    return this.current;
  }

  /**
   * Adds new a new registration step at the end of the list.
   * @name: string
   * represents the step id configured in `modules/user/registration/common/registration-steps.ts`
   * */
  add(name: string) {
    const registrationStep = userRegistrationSteps[name] as IRegistrationStep;

    if (!registrationStep) {
      throw new Error(`Registration step (${name}) is not configured.`);
    }

    if (this.steps.find(i => i.id === name)) {
      throw new Error(`This step (${name}) is already configured.`);
    }

    registrationStep.prev = true;
    registrationStep.next = false;
    registrationStep.options = { submitted: false };
    this.steps[this.steps.length - 1].next = true;

    this.steps.push(registrationStep);
  }

  /** Adds a new registration step after the current step.
   * @name: string
   * represents the step id configured in `modules/user/registration/common/registration-steps.ts`
   * */
  addNext(name: string) {
    const registrationStep = userRegistrationSteps[name] as IRegistrationStep;

    if (!registrationStep) {
      throw new Error(`Registration step (${name}) is not configured.`);
    }

    if (this.steps.find(i => i.id === name)) {
      throw new Error(`This step (${name}) is already configured.`);
    }

    this.current.next = true;
    registrationStep.prev = true;
    registrationStep.next = this.index < (this.availableSteps.length - 1);
    registrationStep.options = { submitted: false };

    this.steps.splice(this.index + 1, 0, registrationStep);
  }

  /**
   * Moves the current registration step to the first step.
   * */
  reset(): IRegistrationStep {
    this.index = 0;
    this.current = this.steps[this.index];

    return this.current;
  }

  /**
   * Moves the current registration step to the next step.
   * */
  next(): IRegistrationStep {
    while(this.index < this.steps.length - 1 ){
      this.index = Math.min(++this.index, this.steps.length - 1);
      const step = this.steps[this.index];
      if(!step.disabled){
        this.current = step;
        break;
      }
    }
    return this.current;
  }

  /**
   * Moves the current registration step to the previous step.
   * */
  prev(): IRegistrationStep {
    while (this.index > 0) {
      this.index = Math.max(--this.index, 0);
      const step = this.steps[this.index];
      if(!step.disabled){
        this.current = step;
        break;
      }
    }
    return this.current;
  }

  changeStepDisabled(): boolean {
    return !this.steps || !!this.steps.some((s) => s.options.pending);
  }
}
