(function () {

  angular.module('kmi.lms.user.components')
    .component('changePasswordModalComponent', {
      template: require('ajs/modules/user/edit/components/modal/change-password-modal.html').default,
      controller: ChangePasswordModalController,
      controllerAs: 'vm',
      bindings: {
        resolve: '=',
        modalInstance: '='
      }
    });

  /* @ngInject */
  function ChangePasswordModalController($scope, rootScopeService, serverErrorHandlerService) {
    var vm = this;

    vm.submit = submitPassword;
    vm.cancel = cancelModal;
    vm.$onInit = onInit;

    function onInit() {
      vm.user = angular.copy(vm.resolve.user);
    }

    function submitPassword() {
      var data = {
        'password': vm.user.password,
        'oldPassword': vm.user.oldPassword
      };

      if ($scope.changePasswordForm.$valid && !$scope.changePasswordForm.$pending) {
        vm.loading = vm.user.changePassword(data)
          .then(function () {
            vm.modalInstance.close(data);
          }, function (reason) {
            if (reason.status === 403 && reason.data && reason.data.code === 'PASSWORD_RULES_FAILED') {
              rootScopeService.broadcast('event:passwordRulesStatusUpdated', reason.data.context);
            }

            return serverErrorHandlerService.handleForbiddenError(reason, function (data) {
              vm.error = data;
            });
          })
          .finally(function () {
            vm.loading = null;
          });
      }
    }

    function cancelModal() {
      vm.modalInstance.dismiss('cancel');
    }
  }
})();
