(function (){
  angular.module('kmi.lms.user.common')
    .component('userPhoneInput', {
      template: require('ajs/modules/user/components/user-phone-input.html').default,
      controller: UserPhoneInputController,
      controllerAs: 'vm',
      bindings: {
        user: '=',
        formSubmitted: '=',
        requiredField: '<?',
      }
    });

  function UserPhoneInputController(){
    const vm = this;

    vm.$onInit = onInit;
    function onInit(){
      vm.requiredField = angular.isDefined(vm.requiredField)? vm.requiredField : true;
    }
  }
})();
