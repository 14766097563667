(function () {

  angular.module('kmi.lms.user.learningSeries')
    .factory('UserLearningSeries', userLearningSeries);

  /**/
  function userLearningSeries($resource) {
    var UserLearningSeries = $resource('/a/user/:userId/learning_series/:lsId/', {userId: '@userId', lsId: '@learningSeries.id'}, {
      query: {
        method: 'GET',
        params: {userId: '@userId'},
        isArray: false,
        transformResponse: transformResponse
      },
      get: {
        method: 'GET',
        params: {userId: '@userId', lsId: '@lsId'},
        isArray: false,
        transformResponse: transformResponse
      }
    }, {
      stripTrailingSlashes: false
    });

    return UserLearningSeries;


    function transformResponse(data, headersGetter, status) {
      if (status === 200) {
        var wrapped = angular.fromJson(data);
        angular.forEach(wrapped.courses, function (item, idx) {
          wrapped.courses[idx] = new UserLearningSeries(item);
        });
        return wrapped;
      }

      return data;
    }
  }


})();
