(function () {

  angular.module('kmi.lms.user.common')
    .factory('userGroupsService', function ($http, Group, _) {
      return {
        getGroups: getGroups,
        getGroupTags: getGroupTags,
        getManagedGroups: getManagedGroups,
        getTopLevelGroups: getTopLevelGroups,
        getStates: getStates,
        getOrganizations: getOrganizations
      };

      function getGroups(query) {
        var queryJsonString = angular.toJson(query);
        return Group.query({query: queryJsonString})
          .$promise
          .then(function (data) {
            return data.items;
          });
      }

      function getGroupTags() {
        return $http.get('/a/user/me/groups/tags/', {cache: true})
          .then(function (response) {
            return response.data;
          });
      }

      function getTopLevelGroups(groups, categoryId) {
        return $http.get('/a/user/me/groups/top_level/', {params: {groups: groups, categoryId: categoryId}, cache: true})
          .then(function (response) {
            return response.data;
          });
      }

      function getManagedGroups(roles) {
        return $http.get('/a/user/my_admin_groups/', {params: {roles: roles}, cache: true})
          .then(function (response) {
            return _.uniqBy(response.data, function (group) {
              return group.id;
            });
          });
      }

      function getStates() {
        return $http.get('/a/user/states/', {cache: true});
      }

      function getOrganizations(userId) {
        return $http.get(['/a/user/', userId, '/organizations/'].join(''), {cache: true})
          .then(function (response) {
            return response.data;
          });
      }
    });

})();
