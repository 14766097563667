(function () {

  angular.module('kmi.lms.user.components')
    .directive('editUserNetworkInfo', editUserNetworkInfo);

  function editUserNetworkInfo() {
    return {
      restrict: 'AE',
      scope: {
        user: '=',
        networkSettings: '='
      },
      template: require('ajs/modules/user/edit/components/network-info.html').default,
      controller: EditUserNetworkInfoController,
      controllerAs: 'vm',
      bindToController: true
    };
  }

  /* @ngInject */
  function EditUserNetworkInfoController($scope, UserNetworkSettings, courseService, currentUser, _) {
    var vm = this;

    vm.$onInit = activate;

    function activate() {
      vm.selfEdit = vm.user && vm.user.id === currentUser.get().id;

      if (vm.user.id) {
        UserNetworkSettings.get({userId: vm.user.id}, function (networkSettings) {
          vm.networkSettings = networkSettings;
        });
      } else {
        vm.networkSettings = new UserNetworkSettings();
      }

      courseService.getCourseOptions()
        .then(function (options) {
          vm.subjectAreas = options.subjectAreas;
        });

      $scope.$on('event:user.save', function (event, user) {
        if (user.subjectAreas) {
          user.subjectAreas = _.map(user.subjectAreas, function (item) {
            return item.id || item;
          });
        }
      });
    }
  }
})();
