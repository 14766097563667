(function () {

  angular.module('kmi.lms.user.certificates')
    .component('editExternalCertificateComponent', {
      template: require('ajs/modules/user/certificates/external/edit-external-certificate.html').default,
      controller: EditExternalCertificateController,
      controllerAs: 'vm',
      bindings: {
        certificate: '='
      }
    });

  /* @ngInject */
  function EditExternalCertificateController($scope, $uibModal, notificationService, backUrlService, urlVersionService,
    _) {
    var vm = this;
    vm.$onInit = onInit;

    vm.backUrl = backUrlService;
    vm.forms = {
      submitted: false
    };

    vm.submit = submit;
    vm.removeCertificate = removeCertificate;

    function onInit() {
      $scope.$on('event:fileUploadProgress', onFileUploadProgress);

      function onFileUploadProgress(event, isUploading) {
        vm.fileUploadProgress = isUploading;
      }
    }

    function submit() {
      vm.forms.submitted = true;

      if (vm.editCertificateForm.$valid) {
        var certificateDraft = angular.copy(vm.certificate);

        if (certificateDraft.id) {
          vm.savePromise = certificateDraft.$update();
        } else {
          vm.savePromise = certificateDraft.$save();
        }

        vm.savePromise
          .then(function handleResponse() {
            angular.extend(vm.certificate, certificateDraft);
            notificationService.info('Your changes have been saved.', 3e3);
            urlVersionService.update();
            redirectBack();
          }, handleError)
          .finally(function () {
            vm.savePromise = null;
            if (vm.editCertificateForm) {
              vm.editCertificateForm.$setPristine();
            }
          });
      } else {
        notificationService.hideInfo();
        notificationService.error('Please fill in all required fields', 2e3);
      }

      function handleError(reason) {
        notificationService.hideInfo();
        if (_.includes([400, 403], reason.status)) {
          notificationService.error(reason.data);
        } else {
          notificationService.error('An error occurred saving certificate data. Please try again later.', 5e3);
        }
      }
    }

    function removeCertificate() {
      var modalInstance = $uibModal.open({
        component: 'deleteExternalCertificateModalComponent',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          certificate: function () {
            return vm.certificate;
          }
        }
      });

      modalInstance.result.then(redirectBack);
    }

    function redirectBack() {
      backUrlService.goBack();
    }
  }
})();

