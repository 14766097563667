(function () {

  angular.module('kmi.lms.search.common')
    .constant('searchRequestItemCount', 20)
    .factory('searchService', ['$http', 'searchRequestItemCount', 'apiUrl', '$q', '$httpParamSerializer', '$window',
      'courseService', '_',
      function ($http, requestItemCount, apiUrl, $q, $httpParamSerializer, $window, courseService,
        _) {
        var SearchService = function (options) {
          options = angular.extend({
            initialRequestItemCount: 80,
            sequentRequestItemsCount: requestItemCount
          }, options);


          var _lastRequestItemCount, _params = {}, _objectTypes = [], _extendedObjectTypes = [], _httpConfig;

          this.search = function (searchParams) {
            var self = this.search;

            if (self.resolver && _.some($http.pendingRequests, {timeout: self.resolver.promise})) {
              self.resolver.resolve();
            }

            self.resolver = $q.defer();

            _lastRequestItemCount = options.initialRequestItemCount;

            // save requested search parameters in the internal _params object
            _params = {
              // internal parameters for infinite scroll
              startDoc: 0,
              count: _lastRequestItemCount
            };

            if (searchParams) {
              if ('showInactive' in searchParams) {
                if (typeof searchParams.showInactive === 'boolean' && !searchParams.showInactive ||
                  typeof searchParams.showInactive === 'string' && searchParams.showInactive !== 'true') {
                  searchParams.active = true;
                }
                searchParams.showInactive = null;
              }
              if ('showExpired' in searchParams) {
                if (typeof searchParams.showExpired === 'boolean' && searchParams.showExpired ||
                  searchParams.showExpired === 'true') {
                  searchParams.expired = true;
                }
                searchParams.showExpired = null;
              }
              if ('showUnpublished' in searchParams) {
                if (typeof searchParams.showUnpublished === 'boolean' && searchParams.showUnpublished ||
                  searchParams.showUnpublished === 'true') {
                  searchParams.published = false;
                } else {
                  searchParams.published = true;
                }
                searchParams.showUnpublished = null;
              }
            }

            angular.extend(_params, searchParams);

            _httpConfig = {
              method: options.method || 'GET',
              url: options.endpoint,
              timeout: self.resolver.promise
            };

            if (options.method === 'POST') {
              _params = _.pickBy(_params, function (val) {
                return val !== null && val !== undefined;
              });

              _httpConfig.data = _.mapValues(_params, function (val) {
                return val + '';
              });
            } else {
              _httpConfig.params = _params;
            }

            return $http(_httpConfig);
          };

          this.getSearchExportLink = function () {
            var searchQuery = _.omit(_params, ['count', 'page', 'rows', 'startDoc']);
            return options.endpoint + 'export/?' + $httpParamSerializer(searchQuery);
          };

          this.getPage = function (page) {
            _params.startDoc = page * options.sequentRequestItemsCount;
            _params.count = options.sequentRequestItemsCount;
            _lastRequestItemCount = _params.count;

            return $http.get(options.endpoint, {
              params: _params
            });
          };

          this.searchNext = function () {
            if (angular.isDefined(_params.startDoc)) {
              // internal parameters for infinite scroll
              _params.startDoc += _lastRequestItemCount;
              _params.count = options.sequentRequestItemsCount;

              _lastRequestItemCount = _params.count;
            }

            return $http.get(options.endpoint, {
              params: _params
            });
          };

          this.getObjectTypes = function () {
            function bindContentTypes(contentTypes) {
              // Announcements are not supported yet.
              var options = _.filter(contentTypes, function (option) {
                return !_.includes(['announcement'], option.value);
              });

              angular.forEach(options, function (option) {
                _objectTypes.push({
                  name: option.name,
                  term: 'type',
                  value: option.value + ''
                });
              });
            }

            var types = JSON.parse(sessionStorage.getItem('objectTypes' + options.mode));
            if (!types) {
              return $http.get(apiUrl(options.endpoint + 'content_types/'))
                .then(function (response) {
                  bindContentTypes(response.data);
                }).then(function () {
                  sessionStorage.setItem('objectTypes' + options.mode, JSON.stringify(_objectTypes));
                  return _objectTypes;
                });
            } else {
              var def = $q.defer();
              def.resolve(types);
              return def.promise;
            }
          };

          this.getExtendedObjectTypes = function () {
            function bindCourseFormats(options) {
              // "Course" will be replaced with set of course formats.
              _extendedObjectTypes = _.filter(_extendedObjectTypes, function (objectType) {
                return !_.includes(['course'], objectType.value);
              });

              if (options.labels && options.labels.length > 0) {
                angular.forEach(options.labels, function (option) {
                  _extendedObjectTypes.push({
                    name: option.name,
                    term: 'label_id',
                    value: option.id + ''
                  });
                });

                _extendedObjectTypes.push({
                  name: 'Unassigned',
                  term: 'label_id',
                  value: 'unassigned'
                });
              } else {
                angular.forEach(options.formats, function (option) {
                  _extendedObjectTypes.push({
                    name: option.name,
                    term: 'format_id',
                    value: option.id + ''
                  });
                });
              }
            }

            var types = JSON.parse(sessionStorage.getItem('extendedObjectTypes'));
            if (!types) {

              return this.getObjectTypes()
                .then(function (objectTypes) {
                  _extendedObjectTypes = objectTypes;

                  //Extend filter with the course formats
                  if (_.find(objectTypes, {value: 'course'})) {
                    return courseService.getCourseOptions().then(bindCourseFormats);
                  }
                }).then(function () {
                  sessionStorage.setItem('extendedObjectTypes', JSON.stringify(_extendedObjectTypes));

                  return _extendedObjectTypes;
                });
            } else {
              var def = $q.defer();
              def.resolve(types);
              return def.promise;
            }
          };

          this.getSuggestions = function (params) {
            return $http.get('/a/search/completion/', {params: params})
              .then(function (response) {
                return response.data;
              });
          };

          this.getUserSuggestions = function (params) {
            var canceller = $q.defer();
            var parameters = params || {};
            return {
              run: function (params) {
                return $http.get('/a/search/user/', {
                  params: params || parameters,
                  timeout: canceller.promise
                })
                  .then(function (response) {
                    return response.data;
                  });
              },
              cancel: function () {
                canceller.resolve();
                canceller = $q.defer();
              }
            };
          };
        };

        function getSearchService(options) {
          var modeEndpoints = {
            'public': '/a/search/',
            'admin': '/a/admin/search/',
            'reportAdmin': '/a/admin/search/report/',
            'assign_courses': '/a/admin/search/assign_courses/',
            'assign_users': '/a/admin/search/assign_users/',
            'group': '/a/admin/search/group/',
            'adminUserCoursesSearch': '/a/admin/search/users/{{userId}}/courses/',
            'events': '/a/admin/search/events/',
            'availableResources': '/a/admin/search/available_resources/'
          };
          var linkTemplate;

          options = options || {};

          if (!options.mode) {
            options.mode = 'public';
          }

          linkTemplate = _.template(modeEndpoints[options.mode]);

          angular.extend(options, {endpoint: linkTemplate(options)});

          return new SearchService(options);
        }

        return {
          get: getSearchService
        };

      }]);
})();
