(function () {

  angular.module('kmi.lms.user.certificates')
    .component('userCertificateTiles', {
      template: require('ajs/modules/user/certificates/certificate-tiles.html').default,
      controller: UserCertificateTilesController,
      controllerAs: 'vm',
      bindings: {
        userId: '<',
        registration: '<',
        hasCertificates: '=?',
        readOnly: '<?'
      }
    });

  /* @ngInject */
  function UserCertificateTilesController($state, $uibModal, userCertificatesService) {
    var vm = this;

    vm.$onInit = onInit;
    vm.generateCertificate = generateCertificate;
    vm.editExternalCertificate = editExternalCertificate;

    function onInit() {
      userCertificatesService.getRegistrationCertificates(vm.registration.id)
        .then(function (certificatesData) {
          vm.certificatesData = certificatesData;

          vm.certificatesData.certificates_list = userCertificatesService.wrapCertificates(vm.certificatesData.certificates_list);
          vm.hasCertificates = !!vm.certificatesData.certificates_list.length;
          vm.resetAllowed = !!vm.certificatesData.resetAllowed;
        });
    }

    function generateCertificate(certificate) {
      return userCertificatesService.generateCertificate(vm.registration.id, certificate);
    }

    function editExternalCertificate() {
      if (vm.certificatesData && !vm.certificatesData.hasExternalCertificates) {
        $uibModal.open({
          component: 'certificateReplacementDialog',
          keyboard: false
        })
          .result.then(function () {
            $state.go('edit.registrationExternalCertificate', {registrationId: vm.registration.id});
          });
      } else {
        $state.go('edit.registrationExternalCertificate', {registrationId: vm.registration.id});
      }
    }
  }
})();
