(function () {

  angular.module('kmi.lms.user.edit')
    .component('editUserAccount', {
      template: require('ajs/modules/user/edit/edit-account.html').default,
      controller: EditAccountController,
      controllerAs: 'vm',
      bindings: {
        user: '='
      }
    });

  /* @ngInject*/
  function EditAccountController(
    $scope, $uibModal, $state, backUrlService, currentUser, userRequirementsService, $http
  ) {
    var vm = this;
    vm.$onInit = onInit;

    vm.fileUploadProgress = false;
    vm.validationPending = false;

    vm.cancel = cancel;
    vm.saveAccount = saveAccount;

    function onInit() {
      vm.currentStateName = $state.current.name;

      $scope.$on('event:user.saved', function (event, account) {
        currentUser.set(account);
        vm.userForm.$setPristine();
        cancel();
      });

      $scope.$on('event:validation.pending', function (event, pending) {
        vm.validationPending = pending;
      });

      $scope.$on('event:fileUploadProgress', function (event, isUploading) {
        vm.fileUploadProgress = isUploading;
      });

      $scope.$watch(function () {
        return $http.pendingRequests.length;
      }, function () {
        vm.loading = !!$http.pendingRequests.length;
      });
    }

    function cancel() {
      userRequirementsService.check('email_confirmation')
        .then(function (response) {
          if (!response) {
            reloginRequired();
          } else if (!($state.transition?.isActive() || $state.current.name !== vm.currentStateName)) {
            backUrlService.goBack();
          }
        });
    }

    function reloginRequired() {
      var modalInstance = $uibModal.open({
        component: 'reconfirmEmailRequiredDialog',
        keyboard: false
      });

      return modalInstance.result;
    }

    function saveAccount() {
      $scope.$broadcast('event:user.save', vm.user);
    }
  }
})();
