(function () {

  angular
    .module('kmi.lms.user.learningSeries')
    .component('legacyLsTreeStructure', {
      template: require('ajs/modules/user/learning-series/components/legacy-ls-tree-structure.html').default,
      controller: UserLearningSeriesStatusController,
      controllerAs: 'vm',
      bindings: {
        item: '<',
        userLearningSeries: '<'
      }
    });

  function UserLearningSeriesStatusController(Course, currentUser, learningSeriesService) {
    var vm = this;

    vm.courseRequirementTypes = learningSeriesService.courseRequirementTypes;
    vm.isAnonymous = currentUser.get().anonymous;

    vm.isNumber = angular.isNumber;
  }
})();
