(function () {

  angular.module('kmi.lms.search.common')
    .component('catalogSearchBar', {
      template: require('ajs/modules/search/components/catalog-search-bar.html').default,
      controller: CatalogSearchBarController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function CatalogSearchBarController($scope, $state, $location, $element) {
    var vm = this;

    vm.searchSubmitted = false;
    vm.search = search;
    vm.clearInput = clearInput;
    vm.$onInit = onInit;

    function onInit() {
      vm.searchQuery = $location.search().catalog_query;

      vm.searchSubmitted = !!vm.searchQuery;

      $scope.$watch(function () {
        return $location.search();
      }, function (newValue) {
        vm.searchQuery = newValue.catalog_query;
        vm.searchSubmitted = !!vm.searchQuery;
      }, true);
    }

    function clearInput(){
      vm.searchQuery = '';
      vm.searchSubmitted = false;
      $element[0].querySelector('input').focus();
    }

    function search($event) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }

      if ($state.is('main.fullCatalog')) {
        let search = $location.search();
        if (vm.searchQuery) {
          angular.extend(search, {catalog_query: vm.searchQuery});
        } else {
          delete search.catalog_query;
        }

        vm.searchSubmitted = true;
        $location.search(search);
      } else {
        $state.go('main.fullCatalog', {type: 'course', catalog_query: vm.searchQuery});
      }
    }
  }
})();
