(function () {

  angular.module('kmi.lms.user.components')
    .component('emailOptInManagement', {
      template: require('ajs/modules/user/edit/components/email-optin-management.html').default,
      controller: EmailOptInManagementController,
      controllerAs: 'vm',
      bindings: {
        user: '=',
        formOptions: '=',
        disableEmailOptOut: '<?',
        consistentlyView: '<?'
      }
    });

  /* @ngInject */
  function EmailOptInManagementController($scope, userEmailOptInStatuses, userEmailOptInService, siteSettingService,
    globalConfig, _) {
    var vm = this;

    vm.processing = false;
    vm.emailOptInPending = false;
    vm.emailOptOut = false;
    vm.emailoptInUserOptionExists = false;
    vm.siteSettings = siteSettingService.getSiteSetting();
    vm.email = null;
    vm.settings = _.get(globalConfig, 'settings');

    vm.receiveEmailsChanged = receiveEmailsChanged;
    vm.resendEmail = resendEmail;
    vm.$onInit = onInit;
    vm.isEmailEqual = isEmailEqual;

    function onInit() {
      vm.user.originalEmail = vm.user.email;
      vm.disableEmailOptOut = vm.disableEmailOptOut !== undefined ? vm.disableEmailOptOut : _.get(vm.settings, 'emailOptIn.disableOptOut');

      $scope.$on('event:user.saved', updateUserEmailOptInData);
      updateUserEmailOptInData(null, vm.user);
    }

    function receiveEmailsChanged() {
      if (!vm.user.receiveEmails) {
        vm.user.stayInformed = false;
        vm.user.annualNotification = false;
      }

      vm.user.forceOptIn = !vm.emailoptInUserOptionExists &&
        (vm.user.receiveEmails === true || vm.user.receiveEmails === false);
    }

    function updateUserEmailOptInData(event, user) {
      vm.emailOptInPending = _.get(user, 'emailOptInOption.statusId') ===
        userEmailOptInStatuses.emailConfirmationPending;
      vm.emailOptOut = _.get(user, 'emailOptInOption.statusId') === userEmailOptInStatuses.noEmail;
      vm.emailoptInUserOptionExists = !!_.get(user, 'emailOptInOption.statusId');
      vm.user.forceOptIn = !vm.emailoptInUserOptionExists &&
        (vm.user.receiveEmails === true || vm.user.receiveEmails === false);
      vm.email = vm.user.email;
    }

    function resendEmail() {
      vm.processing = true;

      userEmailOptInService.resendAction().then(function () {
        vm.processing = false;
      }, function () {
        vm.processing = false;
      });
    }

    function isEmailEqual() {
      return vm.user.email && vm.email && vm.user.email.toLowerCase().trim() === vm.email.toLowerCase().trim();
    }
  }
})();
