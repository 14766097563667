(function(){

  angular.module('kmi.lms.user.common')
    .factory('genderService', GenderService);

  /* @ngInject */
  function GenderService($http, $cacheFactory, apiUrl){
    var _genderListCache = $cacheFactory('genderListCache');
    var service = {
      getGenderList:  _getGenderList
    };

    return service;

    //////////

    function _getGenderList() {
      return $http.get(apiUrl('/a/user/genders/'), {cache: _genderListCache}).then(function(response){
        return response.data.genders;
      });
    }
  }

})();
