(function () {

  /**
   * @ngdoc service
   * @name kmi.lms.quiz.service:quizOptionsService
   * @description
   * This service is used to obtain quiz dependant options, lookups
   *
   */

  angular.module('kmi.lms.quiz')
    .factory('quizOptionsService', quizOptionsService);

  /* @ngInject */
  function quizOptionsService($http, $cacheFactory, _, quizEnums, quizQuestionEnums) {
    var quizOptionsCache = $cacheFactory('quizOptions');

    return {
      getOptions: getOptions,
      getQuizQuestionTypes: getQuizQuestionTypes,
      isQuizTypeScorable: isQuizTypeScorable,
      isQuizEvaluation: isQuizEvaluation,
      isQuestionOptional: isQuestionOptional,
      getHybridResultTypes: getHybridResultTypes
    };

    /**
     * @description
     * Retrieves quiz lookups and stores them in the cache.
     * @returns {IPromise<TResult>|*}
     */
    function getOptions() {
      return $http.get('/a/quizzes/options/', {cache: quizOptionsCache})
        .then(function (response) {
          return response.data;
        });
    }

    /**
     * @description
     * Returns a list of available question types for the corresponding quiz.
     * For example "Short Answer" question available only for non-scorable quizzes like evaluations.
     *
     * @param scorable
     * @param quizTypeId
     */
    function getQuizQuestionTypes(scorable, quizTypeId) {
      return getOptions().then(function (options) {
        var allowedTypes = [];

        for (var i = 0; i < options.questionTypes.length; i++) {
          var type = options.questionTypes[i];
          if (quizQuestionEnums.availableQuestionTypes[quizTypeId].indexOf(type.id) !== -1) {
            for (var j = 0; j < type.extensions.length; j++) {
              var extension = type.extensions[j];
              var typeExtensionTemplate = _.find(quizQuestionEnums.typeExtensionTemplates, {extensionId: extension.typeExtensionId});
              if (typeExtensionTemplate) {
                extension.answerVariants = typeExtensionTemplate.answerVariants;
              }
              allowedTypes.push(extension);
            }
            allowedTypes.push(type);
          }
        }

        // sort
        allowedTypes = _.sortBy(allowedTypes, 'order');

        //Filter question types in accordance with the quiz scoreTypeId and question scorable flag
        return _.filter(allowedTypes, function (item) {
          if (scorable) {
            return item.scorable;
          }

          return true;
        });
      });
    }

    function isQuizTypeScorable(quizTypeId) {
      return !_.includes([quizEnums.quizTypes.evaluation, quizEnums.quizTypes.preEvaluation, quizEnums.quizTypes.followupEvaluation, quizEnums.quizTypes.survey], quizTypeId);
    }

    function isQuizEvaluation(quizTypeId) {
      return _.includes([quizEnums.quizTypes.evaluation, quizEnums.quizTypes.preEvaluation, quizEnums.quizTypes.followupEvaluation], quizTypeId);
    }

    function isQuestionOptional(quizTypeId, questionTypeId) {
      return getOptions().then(function (options) {
        return _.get(_.find(options.courseQuizTypes, {id: quizTypeId}), 'allowOptionalQuestions') &&
          _.get(_.find(options.questionTypes, {id: questionTypeId}), 'skipQuestionAvailable');
      });
    }

    function getHybridResultTypes() {
      return getOptions().then(function (options) {
        return _.flatten(_.map(options.resultTypes /* 1,Points based; 2,Pass/Fail*/, function (resultType) {
          if (resultType.id === 1) {
            return _.map(options.scoreTypes /*1,Percentage; 2,Points*/, function (scoreType) {
              return {
                id: ''+resultType.id + scoreType.id,
                name: scoreType.name,
                scoreTypeId: scoreType.id,
                resultTypeId: resultType.id
              };
            });
          } else {
            return {
              id: ''+resultType.id,
              name: resultType.name,
              scoreTypeId: 2,
              resultTypeId: resultType.id
            };
          }
        }));
      });
    }
  }

})();
